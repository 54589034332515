<ion-content class="ion-padding-start ion-padding-bottom">
  <button class="text-black flex items-center gap-4 text-xl w-full" (click)="openProfile()" *ngIf="!anonymous">
    <ion-icon name="person-outline" color="dark"></ion-icon>
    <p class="text-xl font-medium">Profil</p>
  </button>
  <div class="text-black flex items-center gap-4 text-xl cursor-pointer" (click)="logout()">
    <ion-icon *ngIf="!anonymous" name="log-out-outline" color="dark"></ion-icon>
    <ion-icon *ngIf="anonymous" name="log-in-outline" color="dark"></ion-icon>
    <p class="text-xl font-medium">{{ anonymous ? 'Anmelden' : 'Abmelden' }}</p>
  </div>
</ion-content>
