export const messages: { [key: string]: string } = {
  salutation: "Anrede",
  first_name: "Vorname",
  last_name: "Nachname",
  email: "E-Mail",
  phone: "Telefonnummer",
  mobile: "Handynummer",

  street: "Straße",
  house_no: "Hausnummer",
  post_code: "PLZ",
  city: "Stadt",
  country: "Land",
  website: "Webseite",

  name: "Name",
  description: "Beschreibung",
  folder: "Projekt",
  share: "Mit Team geteilt",

  share_company: "Mit Team teilen",
  share_contacts: "Mit einzelnen Kontakten Teilen",
};
