<div class="rounded-lg shadow-lg bg-white relative p-5 flex flex-col justify-between min-h-36">
  <div>
    <h2 class="text-lg font-bold mb-2">{{ folderName }}</h2>
  </div>
  <div class="py-2.5 px-4 pt-0 flex gap-3">
    <p>Planungen: {{ count }}</p>
  </div>
  <div class="py-2.5 px-4 pt-0 flex gap-3">
    <p>Erstellt am: {{ createdAt | date : 'dd.MM.yyyy' }}</p>
  </div>
  <div class="py-2.5 px-4 pt-0 flex gap-3" *ngIf="creator">
    <p>Erstellt von: {{ creator }}</p>
  </div>
  <div class="flex items-end justify-between mt-4">
    <div class="flex gap-1.5 items-center cursor-pointer">
      <ion-icon name="trash-bin-outline" color="primary" *ngIf="showDelete" class="h-6 w-6" (click)="deleteFolder()"></ion-icon>
    </div>
    <div class="flex gap-1.5 items-center cursor-pointer" (click)="openFolder()">
      <div class="text-lg text-primary font-bold flex items-center">Zum Ordner
        <ion-icon class="ml-2" name="chevron-forward-outline" color="primary"></ion-icon>
      </div>
    </div>
  </div>
</div>
