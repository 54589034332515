import {Injectable, isDevMode} from '@angular/core'
import {AuthChangeEvent, AuthSession, createClient, Session, SupabaseClient,} from '@supabase/supabase-js'
import {environment} from "../../../environments/environment";
import {TablesInsert} from "../../../models/database.types";

export interface Profile {
  id?: string
  username: string
  first_name: string
  last_name: string
  website: string
  avatar_url: string
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient
  _session: AuthSession | null = null

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  getSupabase() {
    return this.supabase;
  }

  get session() {
    this.supabase.auth.getSession().then(({data}) => {
      this._session = data.session
    })
    return this._session
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  signIn(email: string) {
    return this.supabase.auth.signInWithOtp({email})
  }

  signInWithPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({email, password})
  }

  signUp(data: any) {
    return this.supabase.auth.signUp({
      email: data.email, password: data.password, options: {
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          type: 'authenticated',
          company_id: data.company_id ?? null,
        },
        emailRedirectTo: isDevMode() ? 'http://localhost:8100' : 'https://reber-planner.winkler-software.io'
      }
    })
  }

  signOut(scope: "global" | "local" | "others" | undefined) {
    return this.supabase.auth.signOut({scope})
  }

  updateProfile(profile: TablesInsert<'profiles'>) {
    const update = {
      ...profile,
      updated_at: new Date(),
    }

    return this.supabase.from('profiles').upsert(update).select()
  }

  getProfile(profileId: string) {
    return this.supabase.from('profiles').select('*').eq('id', profileId).maybeSingle()
  }

  deleteProfile(profileId: string) {
    return this.supabase.from('profiles').delete().eq('id', profileId)
  }

  deleteAuthUser(userId: string) {
    return this.supabase.rpc('delete_user_by_id', {user_id: userId});
  }


  updateContact(contact: TablesInsert<'contacts'>) {
    const update = {
      ...contact,
      updated_at: new Date(),
    }

    return this.supabase.from('contacts').upsert(update).select()
  }

  getContactByEmail(email: string) {
    return this.supabase.from('contacts').select('*').eq('email', email).select()
  }

  getContactById(contactId: string) {
    return this.supabase.from('contacts').select('*').eq('id', contactId).maybeSingle()
  }

  deleteContact(contactId: string) {
    return this.supabase.from('contacts').delete().eq('id', contactId)
  }

  getContactsByCompanyId(companyId: string) {
    return this.supabase
      .from('contacts')
      .select(`
        *,
        profiles!inner(id, company_id)
      `)
      .eq('profiles.company_id', companyId);
  }

  getProfilesByCompanyId(companyId: string) {
    return this.supabase
      .from('profiles')
      .select('*')
      .eq('company_id', companyId);
  }

  updateAddress(address: TablesInsert<'addresses'>) {
    const update = {
      ...address,
      updated_at: new Date(),
    }

    return this.supabase.from('addresses').upsert(update).select()
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path)
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file)
  }

  signInAnonymously() {
    return this.supabase.auth.signInAnonymously(
      {
        options: {
          data: {
            first_name: '',
            last_name: '',
            type: 'anonymous'
          }
        }
      }
    );
  }

  forgotPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email);
  }

  confirmMail(token: string) {
    return this.supabase.auth.verifyOtp({token_hash: token, type: 'signup'})
  }
}
