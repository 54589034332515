import {Component, NgZone, OnInit} from '@angular/core';
import {IonApp, IonRouterOutlet} from '@ionic/angular/standalone';
import {Router} from "@angular/router";
import {SupabaseService} from "./supabase.service";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private zone: NgZone
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }

  ngOnInit() {
    this.supabase.authChanges((_, session) => {
      if (session?.user) {
        this.zone.run(() => {
          if (this.router.url.includes('login') || this.router.url === '/') {
            // this.router.navigate(['/dashboard'])
          }
        });
      }
    })
  }
}
