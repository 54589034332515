import {createStore} from '@ngneat/elf';
import {Injectable} from '@angular/core';
import {addEntities, selectAllEntities, withEntities} from '@ngneat/elf-entities';
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {stateHistory} from "@ngneat/elf-state-history";
import {uuidv4} from "../../utils/uuid";
import {Tables} from "../../models/database.types";

export const planningStore = createStore(
  {name: 'plannings'},
  withEntities<Tables<'plannings'>>()
);

export const persistCartItemsStore = persistState(planningStore, {
  key: 'cartItems',
  storage: localStorageStrategy
});

export const cartItemsStoreHistory = stateHistory(planningStore, {maxAge: 1000});

@Injectable({providedIn: 'root'})
export class PlanningStore {
  plannings$ = planningStore.pipe(selectAllEntities());

  createPlanning(data: any) {
    const id = uuidv4();
    const name = data.name;
    const description = data.description;
    const folderId = data.folder_id;
    planningStore.update(addEntities({
      id,
      name,
      description,
      created_at: new Date().toDateString(),
      creator_id: 'TEMP',
      company_id: 'TEMP',
      updated_at: null,
      floor_plan: null,
      share: false,
      folder_id: folderId
    }));
  }
}
