import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HeaderComponent} from "../header/header.component";
import {UserService} from "../services/supabase/user.service";
import {IonicModule} from "@ionic/angular";
import {Tables, TablesInsert} from "../../models/database.types";
import {KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {ToastService} from "../services/toast.service";
import {SupabaseService} from "../services/supabase/supabase.service";
import {User} from "@supabase/supabase-js";

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  imports: [
    HeaderComponent,
    IonicModule,
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    KeyValuePipe,
    NgClass
  ],
  standalone: true
})
export class InventoryComponent implements OnInit {
  anonymous = false;
  profile?: Tables<'profiles'>
  inventoryProducts: Tables<'products'>[] = []
  allProducts: Tables<'products'>[] = []
  inventories: Tables<'inventory_products'>[] = []
  selectedInventories: TablesInsert<'inventory_products'>[] = []
  catIndex: number = -1;
  catSizeIndex: number = -1;
  inventoryId: string = ''
  modalIsOpen: boolean = false;

  // Map< category , Map < categorySize, products >>
  productCategories: Map<string, Map<string, Tables<'products'>[]>> = new Map();


  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private supabase: SupabaseService,
    private toastService: ToastService
  ) {
  }

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.params['id'];
    this.inventoryId = id;
    await this.loadAllProducts()
    await this.loadProductsOfInventory(id)
    this.supabase.authChanges(async (_, session) => {
      this.anonymous = session?.user.is_anonymous ?? true;
      if(!this.anonymous) {
        await this.loadProfile(session!.user)
      }
    })
  }

  async loadProfile(user: User) {
    const {data, error} = await this.userService.profile(user)
    if(data) this.profile = data
    if(error){
      console.error(error)
      await this.toastService.presentToast('Beim Laden des Profils ist etwas Schiefgelaufen!.', 5000)
    }
  }

  async loadAllProducts() {
    const {data, error} = await this.userService.getAllProducts()
    if (data && data.length > 0) {
      this.allProducts = data
      this.createProductCategoryMap()
    }
    if (error) {
      console.error(error)
      await this.toastService.presentToast('Beim Laden der Produkte ist ein Fehler aufgetreten!.', 5000)
    }
  }

  async loadProductsOfInventory(id: string) {
    const {data, error} = await this.userService.getInventoryProducts(id)
    if (data && data.length > 0) {
      this.inventories = data
      for (let inventory of data) {
        if (typeof inventory.product_id === 'string') {
          const productRes = await this.userService.getProducts(inventory.product_id)
          if (productRes?.data) this.inventoryProducts.push(...productRes.data)
        }
      }
    }
    if (error) {
      console.error(error)
      await this.toastService.presentToast('Beim Laden der Produkte des Bestands ist ein Fehler aufgetreten!.', 5000)
    }
  }

  async saveAddedProducts() {
    console.log(this.selectedInventories)
    const {data, error} = await this.userService.updateInventories(this.selectedInventories)
    if (data && data.length > 0) {
      this.inventoryProducts = this.allProducts.filter(product =>
        data.some(responseItem => responseItem.product_id === product.id) ||
        this.inventories.some(inventoryProduct => inventoryProduct.product_id === product.id)
      );
    }
    if (error) {
      console.error(error)
      await this.toastService.presentToast('Beim Speichern des Bestands ist ein Fehler aufgetreten!.', 5000)
    }
  }

  createProductCategoryMap() {
    const uniqueCategoriesArray =
      [...new Set(this.allProducts.map((product: Tables<'products'>) => product.category))]
        .filter(p => p !== null);
    uniqueCategoriesArray.forEach(cat => {
      const catSizeMap: Map<string, Tables<'products'>[]> = new Map()
      const catProducts: Tables<'products'>[] = this.allProducts.filter(p => p.category === cat)
      catProducts.forEach(catP => {
        const nameParts = catP.name.split('/')
        if (nameParts.length > 0) {
          const catSize = nameParts[nameParts.length - 1]
          const catSizeProducts = catProducts.filter(p => p.name.endsWith(catSize))
          catSizeMap.set(catSize, catSizeProducts)
        }
        this.productCategories.set(catP.category!, catSizeMap)
      })
    })
  }

  setCatDropdownIdx(catIdx: number, catSizeIdx: number) {
    if (this.catSizeIndex === catSizeIdx && this.catIndex === catIdx) {
      this.catSizeIndex = -1
      this.catIndex = -1
    } else {
      this.catIndex = catIdx
      this.catSizeIndex = catSizeIdx
    }
  }

  toggleSelectedProduct(product: Tables<'products'>, catIdx: number, catSizeIdx: number, pIdx: number) {
    const element = document.getElementById(`${catIdx}-${catSizeIdx}-${pIdx}`) as HTMLInputElement
    const inventoryProduct: TablesInsert<'inventory_products'> = {
      inventory_id: this.inventoryId,
      product_id: product.id,
      amount: 0
    }
    if (element && element.checked) {
      this.selectedInventories.push(inventoryProduct)
      this.inventoryProducts.push(product)
    } else {
      this.selectedInventories = this.selectedInventories.filter((p: any) => p.product_id !== product.id)
      this.inventoryProducts = this.inventoryProducts.filter((p: any) => p.id !== product.id)
    }
  }

  handleAmountInput(target: any, product: Tables<'products'>) {
    const selectedInventory = this.selectedInventories.find(p => p.product_id === product.id)
    if (!selectedInventory && Number(target.value) > 0) {
      const inventory = this.inventories.find(p => p.product_id === product.id)
      if (inventory) inventory.amount = Number(target.value)
      if (inventory && !this.selectedInventories.includes(inventory)) this.selectedInventories.push(inventory)
      return
    }
    if (selectedInventory) selectedInventory.amount = Number(target.value)
  }

  getProductAmount(product: Tables<'products'>) {
    let amount = this.inventories.find((p: any) => p.product_id === product.id)?.amount
    if (!amount) amount = this.selectedInventories.find((p: any) => p.product_id === product.id)?.amount
    return (!!amount && amount > 0) ? amount : ''
  }

  isInInventory(product: Tables<"products">) {
    let inventory: any = this.inventories.find((p: any) => p.product_id === product.id)
    if (!inventory) inventory = this.selectedInventories.find((p: any) => p.product_id === product.id)
    return !!inventory
  }

  async deleteInventoryProduct(product: Tables<"products">) {
    try {
      const res = await this.userService.deleteInventoryProduct(product.id)
      console.log(res)

      this.inventoryProducts = this.inventoryProducts.filter((p: any) => p.id !== product.id)
      this.selectedInventories = this.selectedInventories.filter((p: any) => p.product_id !== product.id)
      this.inventories = this.inventories.filter((p: any) => p.product_id !== product.id)
    } catch (e) {
      console.error(e)
      await this.toastService.presentToast('Beim Löschen des Produktes ist ein Fehler aufgetreten!.', 5000)
    }
  }

  protected readonly Number = Number;
}
