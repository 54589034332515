import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {SupabaseService} from "../../services/supabase/supabase.service";
import {PlanningStore} from "../../../stores/planning/planning.store";
import {Router} from "@angular/router";
import {ToastService} from "../../services/toast.service";
import {Tables} from "../../../models/database.types";
import {SupabaseFolderService} from "../../services/supabase/supabase-folder.service";
import {SupabasePlanningService} from "../../services/supabase/supabase-planning.service";
import {NgForOf, NgIf, NgStyle} from "@angular/common";
import {IonicModule, ModalController} from "@ionic/angular";

@Component({
  selector: 'app-planning-modal',
  templateUrl: './planning-modal.component.html',
  styleUrls: ['./planning-modal.component.scss'],
  imports: [
    NgStyle,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    IonicModule
  ],
  standalone: true
})
export class PlanningModalComponent implements OnInit {
  createNewFolder: boolean = false;
  @Input() isInFolder = false;
  @Input() selectedFolder: string = "";
  @Input() profile?: Tables<'profiles'>

  showFolderInput = false
  folders: Tables<'folders'>[] = []
  newPlaningForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    share: [false],
  })


  constructor(
    private formBuilder: FormBuilder,
    private supabase: SupabaseService,
    private planningStore: PlanningStore,
    private router: Router,
    private toastService: ToastService,
    private folderService: SupabaseFolderService,
    private planningService: SupabasePlanningService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    if (this.profile?.company_id) {
      const response = await this.folderService.getCompanyFolders(this.profile.company_id!)
      if (response?.data && response?.data.length > 0) {
        this.folders = response.data
      }
    }
  }

  async newPlaning() {
    if (this.supabase.session?.user.is_anonymous) {
      this.planningStore.createPlanning(this.newPlaningForm.value)
      await this.modalController.dismiss();
      await this.router.navigate([
        '/start',
        {
          outlets: {
            start: ['settings']
          }
        }
      ], {queryParams: {id: ''}});
    } else {
      if ((!this.selectedFolder || this.selectedFolder === '') && this.newPlaningForm.value.share) {
        await this.toastService.presentToast('Bitte wähle einen Ordner aus wenn du die Planung mit deinem Team teilen willst', 5000)
        return
      }
      let planningData = {
        ...this.newPlaningForm.value,
        company_id: this.newPlaningForm.value.share || this.isInFolder ? this.profile?.company_id : null,
        folder_id: this.folders.find(f => f.name === this.selectedFolder)?.id ?? null,
      };
      if (this.isInFolder) {
        planningData = {
          ...planningData,
          share: true
        };

      }
      const {data, error} = await this.planningService.updatePlanning(planningData);

      if (error) {
        console.error('Error creating planning:', error);
      } else if (data && data!.length > 0) {
        await this.modalController.dismiss();
        await this.router.navigate([
          '/start',
          {
            outlets: {
              start: ['settings']
            }
          }
        ], {queryParams: {id: data![0].id}})
      }
    }
    this.newPlaningForm.reset();
  }


  async selectFolder(event: any) {
    this.selectedFolder = this.folders.find(f => f.name === event.value)?.name ?? '';
  }


  // helper for new folder
  handleShareWithTeam() {
    this.createNewFolder = this.newPlaningForm.value.share ?? false
    this.showFolderInput = true;
  }
}
