import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from "./auth/profile/profile.component";
import {AuthGuard} from "./guards/auth.guard";
import {InventoryComponent} from "./inventory/inventory.component";
import {PlanningsComponent} from "./plannings/plannings.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./planer/start/start.module').then(m => m.StartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory-edit/:id',
    component: InventoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plannings/:folder',
    component: PlanningsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
