import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { Tables } from '../../../models/database.types';

@Component({
  selector: 'app-planning-card',
  templateUrl: './planning-card.component.html',
  standalone: true,
  imports: [IonicModule, DatePipe, NgIf, NgForOf],
  styleUrls: ['./planning-card.component.scss']
})
export class PlanningCardComponent {
  @Input() planning!: Tables<'plannings'>;
  @Input() showDelete: boolean = false;
  @Input() showMove: boolean = false;
  @Input() folders: Tables<'folders'>[] = [];
  @Input() planningIdx: number = -1;
  @Input() showMovePlanningPopup: boolean = false;

  @Output() planningSelect = new EventEmitter<Tables<'plannings'>>();
  @Output() planningDelete = new EventEmitter<Tables<'plannings'>>();
  @Output() planningMoveToggle = new EventEmitter<{planning: Tables<'plannings'>, index: number}>();
  @Output() planningMove = new EventEmitter<{planning: Tables<'plannings'>, folderId: string}>();

  openPlanning() {
    this.planningSelect.emit(this.planning);
  }
  
  deletePlanning() {
    this.planningDelete.emit(this.planning);
  }
  
  toggleMovePopup() {
    this.planningMoveToggle.emit({planning: this.planning, index: this.planningIdx});
  }
  
  moveToFolder(folderId: string) {
    this.planningMove.emit({planning: this.planning, folderId});
  }
}