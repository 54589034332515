import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {HeaderComponent} from "../../header/header.component";
import {IonicModule} from "@ionic/angular";
import {FooterComponent} from "../../home/footer/footer.component";
import {NgForOf, NgIf} from "@angular/common";
import {AuthSession} from "@supabase/supabase-js";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {Tables, TablesInsert} from "../../../models/database.types";
import {ToastService} from "../../services/toast.service";
import {LoadingService} from "../../services/loading.service";
import {SupabaseService} from "../../services/supabase/supabase.service";
import {UserService} from "../../services/supabase/user.service";
import {DynamicFormComponent} from "../dynamic-form/dynamic-form.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [
    HeaderComponent,
    IonicModule,
    FooterComponent,
    NgIf,
    ReactiveFormsModule,
    DynamicFormComponent,
    NgForOf
  ],
  standalone: true
})
export class ProfileComponent implements OnInit {
  @ViewChildren(DynamicFormComponent) forms!: QueryList<DynamicFormComponent>;

  anonymous = false;
  loading = false;
  profile!: TablesInsert<'profiles'>
  contact?: TablesInsert<'contacts'>
  address?: TablesInsert<'addresses'>
  company?: TablesInsert<'companies'>
  companyAddress?: TablesInsert<'addresses'>
  companyContact?: TablesInsert<'contacts'>
  session!: AuthSession

  // all UNUSED invites has no contact information <- must confirm the email first!!!
  invitedContacts: Tables<'contacts'>[] = []
  invites?: Tables<'invites'>[] = []
  notAcceptedInvites: Tables<'invites'>[] = []

  companyForm = this.formBuilder.group({
    id: [''],
    name: ['', Validators.required],
  })

  inviteForm = this.formBuilder.group({
    emails: [''],
  });
  user?: Tables<'profiles'>;

  inviteModalVisible = false;

  constructor(
    private supabase: SupabaseService,
    private userService: UserService,
    private readonly formBuilder: FormBuilder,
    private toastService: ToastService,
    private loadingService: LoadingService,
  ) {
  }

  async ngOnInit() {
    this.supabase.authChanges(async (_, session) => {
      this.anonymous = session?.user.is_anonymous ?? true;
      if (session) {
        this.session = session;
      }
    })
  }

  async ionViewDidEnter() {
    if (!this.anonymous) {
      await this.getProfile();
      await this.getCompanyContactAndAddress()
      await this.getInvites()
    }
  }

  async getProfile() {
    try {
      this.loading = true
      const {user} = this.session
      const {data, error, status} = await this.userService.fullProfile(user)
      if (error && status !== 406) {
        throw error
      }
      if (data) {
        this.profile = data
        console.log(this.profile)
        // @ts-ignore
        this.company = this.profile.company_id
        if (this.company?.id) {
          this.companyForm.patchValue({...this.company})
        }
        if (data.contacts?.length > 0) {
          this.contact = data.contact_id && typeof data.contact_id === 'string'
            ? data.contacts.find((d: any) => d.id === data.contact_id)
            : data.contacts[0]

          const profileForm =
            this.forms.toArray().find(formAttr => formAttr.id === 'profile')

          if (profileForm && profileForm?.form) {
            profileForm.form.patchValue({
              salutation: this.contact?.salutation,
              email: this.contact?.email,
              phone: this.contact?.phone,
              mobile: this.contact?.mobile,
            })
          }
          if (data.contacts.find((d: any) => d.id === this.contact?.id)) {
            const profileContact = data.contacts.find((d: any) => d.id === this.contact?.id)
            if (profileContact.addresses) this.address = profileContact.addresses
            else this.address = data.contacts[0].addresses
          }
          delete data.contacts[0].addresses
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async getCompanyContactAndAddress() {
    try {
      if (this.profile.id && this.company && this.company?.id) {
        const {data, error} = await this.userService.getFullCompanyData(this.company.id)
        if (data) {
          this.companyContact = data.contact_id
          this.companyAddress = data.address_id
        } else throw error
      }
    } catch (e) {
      console.error(e)
      this.loading = false
    }
  }

  async getInvites() {
    try {
      if (!this.company?.id) {
        await this.toastService.presentToast('Es wurde keine Firma gefunden', 5000)
        return
      }

      const {data, error, status} = await this.userService.getInvitesByCompany(this.company.id)
      if (error && status !== 406) throw error

      this.invites = data?.map((d: any) => {
        const inviteData: Tables<'invites'> = {...d}
        return inviteData
      })

      if (this.invites && this.invites?.length > 0) {
        this.invitedContacts = []
        for (const i of this.invites) {
          const resInvitedContacts = await this.supabase.getContactByEmail(i.email)
          if (resInvitedContacts?.data && resInvitedContacts.data.length > 0 && i.used
            && !this.invitedContacts.find((ic: any) => ic.id === resInvitedContacts.data[0].id)) {
            const contactData: Tables<'contacts'> = {...resInvitedContacts?.data[0]}
            this.invitedContacts.push(contactData)
          } else {
            this.notAcceptedInvites.push(i)
          }
        }
        console.log(this.notAcceptedInvites)
      }
    } catch (e) {
      console.error(e)
      await this.toastService.presentToast('Es konnten keine Einladungen geladen werden', 5000)
    }
  }

  async updateUser() {
    try {
      await this.loadingService.show()
      const resProfile = await this.saveEntityForm('profile', 'profiles', this.profile.id)
      if (resProfile?.error) throw resProfile.error
      else if (resProfile?.data) this.profile = resProfile.data[0]

      const resContact = await this.saveEntityForm('profile', 'contacts', this.contact?.id)
      if (resContact?.error) throw resContact.error
      else if (resContact?.data) this.contact = resContact.data[0]

      const resAddress = await this.saveEntityForm('profile_address', 'addresses', this.address?.id)
      if (resAddress?.error) throw resAddress.error
      else if (resAddress?.data) {
        this.address = resAddress.data[0]
        if (this.address?.id && this.contact) {
          this.contact!.address_id = this.address?.id
          await this.supabase.updateContact(this.contact);
        }
      }
    } catch (e) {
      console.error(e)
      await this.toastService.presentToast('Fehler beim Speichern. Versuche es erneut.', 5000)
    } finally {
      await this.loadingService.hide()
    }
  }

  async inviteUsers() {
    if (!this.inviteForm.value.emails) {
      await this.toastService.presentToast('Bitte füll das E-mail Feld aus.', 5000)
      return;
    }
    try {
      await this.loadingService.show()
      const emails: string[] = []
      if (this.inviteForm.value.emails?.includes(',')) {
        const formEmailsInput = this.inviteForm.value.emails?.split(',')
        formEmailsInput.forEach(email => emails.push(email.replace(' ', '')))
      } else {
        emails.push(this.inviteForm.value.emails!)
      }
      if (emails.length <= 0) return

      for (const email of emails) {
        const token = await this.saveInvite(email)
        if (!token) {
          await this.toastService.presentToast('Fehler beim Erstellen der Einladung. Versuche es erneut.', 5000)
          return
        }
        this.sendInviteEmail(email, token)
      }
    } catch (e) {
      await this.toastService.presentToast('Fehler beim versenden der E-Mail. Versuche es erneut.', 5000)
    } finally {
      this.inviteModalVisible = false;
      await this.loadingService.hide()
    }
  }

  async addCompanyProfile() {
    const {data, error} = await this.userService.addCompanyProfile({name: this.companyForm.value.name})
    if (data) {
      this.company = data[0];
      this.profile.company_id = data[0].id
      const res = await this.userService.updateProfile(this.profile)
    } else console.error(error)
  }

  async updateCompany() {
    try {
      await this.loadingService.show()
      const resAddress = await this.saveEntityForm('company_address', 'addresses', this.companyAddress?.id)
      if (resAddress?.data) this.companyAddress = resAddress.data[0]
      else if (resAddress?.error) throw resAddress.error

      const resContact = await this.saveEntityForm('company_contact', 'contacts', this.companyContact?.id)
      if (resContact?.data) this.companyContact = resContact.data[0]
      else if (resContact?.error) throw resContact.error

      const company = !this.company
        ? {
          id: this.companyForm.value.id!,
          name: this.companyForm.value.name,
          address_id: (resAddress?.error || !resAddress) ? this.companyAddress?.id : resAddress?.data[0].id,
          contact_id: (resAddress?.error || !resAddress) ? this.companyContact?.id : resContact?.data[0].id,
        }
        : {
          ...this.company,
          address_id: (resAddress?.error || !resAddress) ? this.companyAddress?.id : resAddress?.data[0].id,
          contact_id: (resAddress?.error || !resAddress) ? this.companyContact?.id : resContact?.data[0].id,
        }
      const {data, error} = await this.userService.updateCompanyProfile(company)
      if (data) this.company = data[0]
      else throw error
    } catch (e) {
      console.error(e)
      await this.toastService.presentToast('Fehler beim Speichern. Versuche es erneut.', 5000)
    } finally {
      await this.loadingService.hide()
    }
  }

  async deleteInvitedContact(contact: Tables<'contacts'>) {
    try {
      await this.supabase.deleteContact(contact.id)
      await this.supabase.deleteProfile(contact.profile_id)
      await this.userService.deleteInviteByEmail(contact.email!)
      await this.supabase.deleteAuthUser(contact.profile_id)
    } catch (e) {
      console.error(e)
      await this.toastService.presentToast('Beim Löschen des Kontakts ist etwas Schiefgelaufen.', 5000)
    }
  }

  async deleteInvited(invited: Tables<"invites">) {
    try {
      await this.userService.deleteInviteByEmail(invited.email!)
    } catch (e) {
      await this.toastService.presentToast('Beim Löschen der Einladung ist etwas Schiefgelaufen.', 5000)
    }
  }

  private async saveEntityForm(formId: string, entityName: string, entityId?: string) {
    try {
      const entityForm = this.forms.toArray()
        .find(formAttr => formAttr.id === formId)
      if (!entityForm || !entityForm.form.touched) return null

      const entity: any = entityName === 'profiles'
        ? {
          ...this.profile,
          id: entityId,
          first_name: entityForm.form.value?.first_name,
          last_name: entityForm.form.value?.last_name,
          website: entityForm.form.value?.website
        }
        : {
          ...entityForm.form.value,
          id: entityId
        }
      if (entityName === 'contacts') entity.profile_id = this.profile.id
      if (entityName === 'contacts' && entity.website) delete entity.website
      if (entityName === 'profiles' && entity.contacts && entity.contacts.length > 0) {
        entity.contact_id = this.contact!.id
        delete entity.contacts
        if (typeof entity.company_id !== 'string') {
          entity.company_id = this.company?.id
        }
      }
      if (entityName === 'profiles' && entity.contacts && entity.contacts.length == 0) {
        delete entity.contacts
      }

      switch (entityName) {
        case 'contacts':
          return await this.supabase.updateContact(entity);
        case 'addresses':
          return await this.supabase.updateAddress(entity);
        case 'profiles':
          return await this.supabase.updateProfile(entity);
        default:
          return null
      }
    } catch (error) {
      console.error(error)
      await this.toastService.presentToast('Fehler beim Speichern. Versuche es erneut.', 5000)
      return null
    }
  }


  async saveInvite(email: string) {
    const invite: TablesInsert<'invites'> = {
      email: email,
      company_id: this.company?.id,
      token: ''
    }
    const resInvites = await this.userService.inviteUser(invite)
    if (resInvites?.data) return resInvites.data[0].token
    else return null
  }

  sendInviteEmail(email: string, token: string) {
    const sendEmailUrl = 'https://mailservice.winkler-software.io/send-email'
    const headers = {
      'Content-Type': 'application/json',
    }
    const reqBody = JSON.stringify({
      to: email.toLowerCase(),
      subject: `${this.contact?.first_name} ${this.contact?.last_name} hat dich eingeladen`,
      body: this.createHtmlEmailBody(token),
    })
    fetch(sendEmailUrl, {method: 'POST', headers: headers, body: reqBody})
      .then(response => response.json())
      .then(res => {
        this.toastService.presentToast('Email wurde erfolgreich versendet', 5000)
      })
      .catch(err => console.error(err))
  }

  // TODO: Create Template or add html here
  createHtmlEmailBody(token: string) {
    const urlWithToken = `http://localhost:8100/auth/sign-up-invite?token=${token}`;
    return `
      <html>
        <body>
         <h2>${this.contact?.first_name} ${this.contact?.last_name} hat dich zu Reber System-Schaltung eingeladen</h2>
         <p>Bitte klick auf den folgenden Link um dich zu registrieren</p>
         <a href=${urlWithToken}>Bei Reber registrieren</a>
        </body>
      </html>
      `
  }
}
