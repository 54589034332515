<div class="rounded-lg shadow-lg bg-white relative p-5 flex flex-col justify-between">
  <div>
    <h2 class="text-lg font-bold mb-2">{{ planning.name }}</h2>
    <p class="text mb-2">{{ planning.description }}</p>
  </div>
  <div class="py-2.5 pt-0 flex gap-3" *ngIf="planning.created_at">
    <p>Erstellt am: {{ planning.created_at | date : 'dd.MM.yyyy' }}</p>
  </div>
  <div class="flex items-end justify-between mt-4">
    <div class="flex gap-1.5 items-center cursor-pointer">
      <ion-icon name="trash-bin-outline" color="primary"  *ngIf="showDelete" class="h-6 w-6" (click)="deletePlanning()"></ion-icon>
    </div>
    <div class="flex items-end flex-col">
      <div *ngIf="showMove"
           id="move-planning-{{planningIdx}}"
           class="flex gap-1.5 items-center cursor-pointer"
           (click)="toggleMovePopup()">
        <div class="text-lg text-primary font-bold mb-2 mt-4 flex items-center">
          Verschieben
          <ion-icon class="ml-2" name="chevron-expand-outline"></ion-icon>
        </div>
        <ion-popover [isOpen]="showMovePlanningPopup && planningIdx >= 0" [trigger]="'move-planning-' + planningIdx"
                     [dismissOnSelect]="true"
                     [reference]="'trigger'" [side]="'bottom'" [alignment]="'start'">
          <ng-template>
            <ion-content>
              <div class="p-4">
                <h3 class="text font-bold">Planung verschieben in:</h3>
                <ion-list *ngIf="folders.length > 0" class="mt-4">
                  <ion-item [button]="true" *ngFor="let folder of folders" (click)="moveToFolder(folder.id)">
                    {{ folder.name }}
                  </ion-item>
                </ion-list>
                <p class="mt-4" *ngIf="folders.length <= 0">Um die Planung in eine Ordner zu verschieben, musst du
                  erst einen Ordner erstellen.</p>
              </div>
            </ion-content>
          </ng-template>
        </ion-popover>
      </div>
      <div class="flex gap-1.5 items-center cursor-pointer" (click)="openPlanning()">
        <div class="text-lg text-primary font-bold flex items-center">
          Zur Planung
          <ion-icon class="ml-2" name="chevron-forward-outline" color="primary"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</div>
