// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  supabaseUrl: 'https://reber-db.winkler-software.io',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzQxOTA2ODAwLAogICJleHAiOiAxODk5NjczMjAwCn0.DDVmx_UlqUUAmWVqe2TVN76yZpmqKcZj_3yxggz7Iow'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames su
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.ch as `zone.run`, `zoneDelegate.invokeTask`.
 *
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/*
LOCAL
supabaseUrl: 'http://localhost:8000',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzI5MjAyNDAwLAogICJleHAiOiAxODg2OTY4ODAwCn0.HFv8179v9NhzUXYXSFdinj9zBH3Ki1AEhNwEeewbFXc'*/


/*HOSTED
supabaseUrl: 'https://xrmwaoneucycxusnmkgi.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhybXdhb25ldWN5Y3h1c25ta2dpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA4ODQ3MjUsImV4cCI6MjA0NjQ2MDcyNX0.ZE8mvHKdmCcxToBNlkca5HLrduUtTVYI6CPUIfpEgdM'
  */

