<app-header [back]="true"></app-header>
<ion-content [fullscreen]="true">
  <div class="p-8">
    <h1 class="text-3xl font-bold mb-4">Dein Bestand</h1>
    <ng-container *ngIf="inventoryProducts.length <= 0">
      <p>Du hast noch keine Artikel zu deinem Lagerbestand hinzugefügt</p>
    </ng-container>
    <ng-container *ngIf="inventoryProducts.length > 0">
      <ng-container *ngFor="let product of inventoryProducts">
        <ion-card class="flex justify-start items-start pl-2.5 pt-2.5 min-h-20">
          <img class="h-16 w-16" src="/assets/example_product_img/reber_product_img.png"/>
          <div class="px-5 w-fit flex">
            <div>
              <ion-card-header class="p-0 pb-0 whitespace-nowrap">
                <ion-card-title class="text-lg flex font-bold">
                  {{ product.name }}
                </ion-card-title>
              </ion-card-header>
              <ion-card-content class="m-0 p-0 whitespace-nowrap">
                <p class="p-0">{{ product.description }} kg, Artikelnummer: {{ product.article_nr }}</p>
              </ion-card-content>
            </div>
          </div>
          <div class="flex gap-5 px-5 w-full h-full justify-end items-center">
            <input type="number"
                   width="100px"
                   [disabled]="anonymous || profile?.role === 'member'"
                   [value]="getProductAmount(product)"
                   (change)="handleAmountInput($event.target, product)"/>
            <ion-icon *ngIf="profile?.role === 'admin'"
                      style="color: red; pointer-events: auto"
                      name="trash-bin-outline"
                      class="m-0 h-6 w-6 cursor-pointer"
                      (click)="deleteInventoryProduct(product)">
            </ion-icon>
          </div>
        </ion-card>
      </ng-container>
    </ng-container>
    <ion-button id="add-article-modal"
                shape="round"
                color="primary"
                data-desc="Add article"
                expand="block"
                class="flex items-center"
                (click)="modalIsOpen = true">
      <ion-icon class="mr-2" name="add-outline"></ion-icon>
      <p>Artikel hinzufügen</p>
    </ion-button>
    <ion-button *ngIf="profile?.role === 'admin'"
                shape="round"
                color="primary"
                fill="outline"
                data-desc="Save inventory"
                expand="block"
                class="flex items-center"
                (click)="saveAddedProducts()">
      <p>Änderung im Bestand speichern</p>
    </ion-button>

    <ion-modal [isOpen]="modalIsOpen" trigger="add-article-modal">
      <ng-template>
        <div class="py-6 px-12 overflow-y-scroll hide-scrollbar-width">
          <h1 class="text-3xl font-bold mb-4">Artiel hinzufügen</h1>
          <div class="flex flex-col mb-8 font-semibold"
               *ngFor="let categoryProducts of productCategories | keyvalue; let catIdx = index">
            {{ categoryProducts.key }}
            <ng-container class="flex"
                          *ngFor="let catSizeProducts of categoryProducts.value | keyvalue; let catSizeIdx = index">
              <div
                class="flex justify-start items-center gap-6 pl-4 py-3 border-b border-gray-400 font-normal cursor-pointer"
                [ngClass]="(catIndex === catIdx && catSizeIndex === catSizeIdx) ? 'border-none' : ''"
                (click)="setCatDropdownIdx(catIdx, catSizeIdx)">
                <ion-icon
                  name="{{!(catIndex === catIdx && catSizeIndex === catSizeIdx) ? 'chevron-down' : 'chevron-up'}}">
                </ion-icon>
                {{ (Number(catSizeProducts.key) / 100).toFixed(2) }}m ({{ catSizeProducts.value.length }})
              </div>
              <ng-container *ngIf="catIndex === catIdx && catSizeIndex === catSizeIdx">
                <ng-container *ngFor="let product of catSizeProducts.value; let productIdx = index">
                  <ion-card class="flex justify-start items-start p-2 min-h-20">
                    <div class="h-full flex items-center justify-center m-auto">
                      <input id="{{ catIdx }}-{{ catSizeIdx }}-{{ productIdx }}"
                             class="custom-checkbox p-0 mr-2.5 max-w-5 min-w-5 max-h-5 min-h-5"
                             type="checkbox"
                             [checked]="isInInventory(product)"
                             (change)="toggleSelectedProduct(product, catIdx, catSizeIdx, productIdx)"/>
                    </div>
                    <img alt="product_img" class="h-16 w-16" src="/assets/example_product_img/reber_product_img.png"/>
                    <div class="px-5 w-fit flex">
                      <div>
                        <ion-card-header class="p-0 pb-0 whitespace-nowrap">
                          <ion-card-title class="text-lg flex font-bold">
                            {{ product.name }}
                          </ion-card-title>
                        </ion-card-header>
                        <ion-card-content class="m-0 p-0 whitespace-nowrap">
                          <p class="p-0">{{ product.description }} kg, Artikelnummer: {{ product.article_nr }}</p>
                        </ion-card-content>
                      </div>
                    </div>
                    <div class="flex gap-5 px-5 w-full h-full justify-end items-center">
                      <input *ngIf="isInInventory(product)"
                             type="number" width="100px" placeholder="Menge"
                             [value]="getProductAmount(product)"
                             (change)="handleAmountInput($event.target, product)"/>
                    </div>
                  </ion-card>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="w-full flex justify-center items-center gap-5 py-5">
          <ion-button shape="round" color="secondary" class="primary w-1/4 min-w-fit" (click)="modalIsOpen = false">
            Abbrechen
          </ion-button>
          <ion-button *ngIf="profile?.role === 'admin'"
                      shape="round"
                      color="primary"
                      class="bg-white w-1/4 min-w-fit"
                      (click)="saveAddedProducts()">
            Ausgewählte Artikel hinzufügen
          </ion-button>
        </div>
      </ng-template>
    </ion-modal>
  </div>
</ion-content>
