import {Injectable} from '@angular/core';
import {SupabaseService} from "./supabase.service";
import {User} from "@supabase/supabase-js";
import {TablesInsert, TablesUpdate} from "../../../models/database.types";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private supabaseService: SupabaseService) {
  }

  profile(user: User) {
    const supabase = this.supabaseService.getSupabase();
    return supabase
      .from('profiles')
      .select(`*`)
      .eq('id', user.id)
      .maybeSingle()
  }

  fullProfile(user: User) {
    const supabase = this.supabaseService.getSupabase();
    return supabase
      .from('profiles')
      .select(`
    *,
    company_id(*),
    contacts(
    *,
          addresses(*))
  `)
      .eq('id', user.id)
      .single()
  }

  inviteUser(invite: TablesInsert<'invites'>) {
    const supabase = this.supabaseService.getSupabase();
    const token = crypto.randomUUID();
    return supabase
      .from('invites')
      .insert(
        [{
          ...invite,
          token
        }]
      )
      .select()
  }

  updateInvite(invite: TablesInsert<'invites'>) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('invites').upsert(invite).select()
  }

  getInviteByToken(token: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('invites').select('*').eq('token', token).single()
  }

  getInviteByEmail(email: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('invites').select('*').eq('email', email).maybeSingle()
  }

  getInvitesByCompany(companyId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('invites').select('*').eq('company_id', companyId).select()
  }

  deleteInviteByEmail(email: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('invites').delete().eq('email', email)
  }

  addCompanyProfile(company: TablesInsert<'companies'>) {
    const supabase = this.supabaseService.getSupabase();

    return supabase
      .from('companies')
      .insert(company)
      .select();
  }

  updateCompanyProfile(company: TablesUpdate<'companies'>) {
    const supabase = this.supabaseService.getSupabase();
    const update = {
      ...company,
      updated_at: new Date(),
    }
    return supabase
      .from('companies')
      .upsert(update)
      .select();
  }

  getFullCompanyData(companyId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase
      .from('companies')
      .select(`*, address_id(*), contact_id(*)`)
      .eq('id', companyId)
      .single()
  }

  async updateProfile(profile: TablesInsert<"profiles">) {
    const supabase = this.supabaseService.getSupabase();
    const update = {
      id: profile.id,
      company_id: profile.company_id,
      updated_at: new Date(),
    }

    return supabase.from('profiles').upsert(update).select()
  }

  getInventoryProducts(inventoryId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase
      .from('inventory_products')
      .select('*, product_id(*)')
      .eq('inventory_id', inventoryId)
      .select()
  }

  getTeamInventoriesByCreatorId(creatorId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('inventories')
      .select()
      .eq('creator_id', creatorId)
      .eq('share', true)
      .order('created_at', {ascending: false})
  }

  updateInventories(inventoryProducts: TablesInsert<'inventory_products'>[]) {
    const supabase = this.supabaseService.getSupabase();
    const updateArray = inventoryProducts.map(product => {
      return {...product, updated_at: new Date()}
    })
    return supabase.from('inventory_products').upsert(updateArray).select()
  }

  deleteInventoryProduct(productId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('inventory_products').delete().eq('product_id', productId)
  }

  getProducts(productId: string) {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('products').select('*').eq('id', productId).select()
  }

  getAllProducts() {
    const supabase = this.supabaseService.getSupabase();
    return supabase.from('products').select('*').select()
  }
}
