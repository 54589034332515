import { Injectable } from '@angular/core';
import {createClient, SupabaseClient} from "@supabase/supabase-js";
import {environment} from "../../../environments/environment";
import {SupabaseService} from "./supabase.service";
import {TablesInsert, TablesUpdate} from "../../../models/database.types";

@Injectable({
  providedIn: 'root'
})
export class SupabaseFolderService {
  private supabase: SupabaseClient

  constructor(
    private supasebaseService: SupabaseService
  ) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  async insertFolder(folder: TablesInsert<'folders'>) {
    return this.supabase.from('folders').insert(folder).select()
  }


  async getCompanyFolders(companyId: string) {
    return this.supabase.from('folders').select('*').eq('company_id', companyId).select()
  }

  async getPlanningCountByFolder(folderIds: string[]) {
    return this.supabase
      .from('plannings')
      .select('folder_id, count', { count: 'exact' })
      .in('folder_id', folderIds)
      .select()
  }

  async getPrivateCreatorFolders() {
    return this.supabase.from('folders')
      .select('*')
      .eq('share_company', false)
      .eq('share_contacts', false)
      .eq('creator_id', this.supasebaseService.session?.user.id)
  }

  async updateFolder(folder: TablesInsert<'folders'>) {
    return this.supabase.from('folders').upsert(folder).select()
  }

  async updateFolderContactRelation(relFolderContact: any) {
    return this.supabase.from('rel_folder_profiles').upsert(relFolderContact).select()
  }

  async deleteFolderProfileRelByFolderId(folderId: string) {
    return this.supabase.from('rel_folder_profiles').delete().eq('folder_id', folderId)
  }

  async getFoldersOfProfileByFolderId(folderId: string) {
    return this.supabase
      .from('rel_folder_profiles')
      .select('*')
      .eq('folder_id', folderId)
  }

  async getFolderByName(folderName: string) {
    return this.supabase.from('folders').select('*').eq('name', folderName).maybeSingle()
  }

  async deleteFolderByName(folderName: string) {
    return this.supabase.from('folders').delete().eq('name', folderName)
  }
}
