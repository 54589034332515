<div class="new_planing_container flex-col p-4">
  <h2 class="text-2xl font-bold w-full text-left" *ngIf="!isInFolder">Neue Planung erstellen</h2>
  <h2 class="text-2xl font-bold w-full text-left" *ngIf="isInFolder">Neue Planung in Ordner {{ selectedFolder }} erstellen</h2>
  <form [formGroup]="newPlaningForm" (ngSubmit)="newPlaning()" novalidate class="w-full">
    <div class="flex w-full flex-wrap my-4">
      <div class="flex w-full flex-wrap my-4">
        <label class="w-full font-bold">Name*</label>
        <input type="text" name="name" formControlName="name" class="w-full" placeholder="Name"/>
      </div>
      <div class="flex w-full flex-wrap my-4">
        <label class="w-full font-bold">Beschreibung</label>
        <input type="text" name="description" formControlName="description" class="w-full"
               placeholder="Beschreibung"/>
      </div>

      <ng-container *ngIf="!isInFolder">
        <div class="flex w-full flex-wrap my-4">
          <ion-checkbox name="share" formControlName="share" labelPlacement="end" (ionChange)="handleShareWithTeam()">
            Planung mit deinem Team teilen?
          </ion-checkbox>
        </div>
        <div [ngStyle]="{'height': showFolderInput ? '' : '0', 'visibility': showFolderInput ? 'visible' : 'hidden'}"
             id="folder-input"
             class="flex w-full flex-wrap my-4">
          <label class="w-full font-bold">Ordner</label>
          <ng-container *ngIf="createNewFolder">
            <ng-container *ngIf="folders.length <= 0">
              <p class="mt-3">Du hast noch keinen Ordner erstellt! Um Planungen einem Ordner hinzuzufügen erstelle
                bitte zuerst einen Ordner.</p>
            </ng-container>
            <ng-container *ngIf="folders.length > 0">
              Planung zu Ordner hinzufügen
              <select id="folder-select"
                      class="w-full p-2 pl-4 border border-gray-300 rounded-full"
                      (change)="selectFolder($event.target)">
                <option>Wähle einen Ordner aus</option>
                <ng-container *ngFor="let folder of folders">
                  <option [value]="folder.name">{{ folder.name }}</option>
                </ng-container>
              </select>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="flex w-full my-2 flex-wrap">
        <ion-button shape="round" type="submit" color="primary" class="primary w-full uppercase"
                    [disabled]="!newPlaningForm.valid">Planung erstellen
        </ion-button>
      </div>
    </div>
  </form>
</div>
