<app-header [back]="true"></app-header>
<ion-content [fullscreen]="true">
  <div class="flex min-h-full flex-wrap  flex-col justify-between">
    <div class="w-full grid xl:grid-cols-2 gap-8 p-8">
      <h1 class="xl:col-span-2 text-3xl font-bold mb-4">Dein Profil</h1>
      <ion-chip class="col-span-2 w-fit mt-2" *ngIf="anonymous" color="danger">Für deine Profilverwaltung und weitere
        Features musst du dich anmelden.
      </ion-chip>
      <div class="flex flex-col gap-4">
        <h2 class="col-span-2 text-2xl font-bold">Deine Nutzerdaten</h2>
        <app-dynamic-form [id]="'profile'" [entityName]="'contacts'" [entity]="profile"></app-dynamic-form>
        <div class="my-2"></div>
        <app-dynamic-form [id]="'profile_address'"
                          [entityName]="'addresses'"
                          [entity]="address"
                          [firstRowFull]=false>
        </app-dynamic-form>
        <div class="flex w-full flex-wrap col-span-2">
          <ion-button (click)="updateUser()" shape="round" color="primary" fill="outline"
                      class="primary w-full uppercase">
            Nutzerdaten speichern
          </ion-button>
        </div>
      </div>

      <div>
        <form class="w-full grid grid-cols-2 gap-4 h-fit" [formGroup]="companyForm"
              (ngSubmit)="updateCompany()">
          <h2 class="col-span-2 text-2xl font-bold">Deine Unternehmesdaten</h2>
          <ng-container *ngIf="!company">
            <div class="col-span-2 flex flex-wrap">
              <p class="w-full text-xl font-bold">Du hast noch kein Firmenprofil erstellt.</p>
              <p class="w-full text-md">Mit einem Firmenprofil kannst du andere Kollegen einladen und ihr könnt eure
                Planungen im Team teilen.</p>
            </div>
          </ng-container>
          <ng-container>
            <input type="hidden" name="id" formControlName="id" class="w-full"/>
            <div class="col-span-2">
              <div class="flex w-1/2 flex-wrap ">
                <label class="w-full font-bold">Name</label>
                <input type="text" name="name" formControlName="name" class="w-full" placeholder="Name"/>
              </div>
            </div>
          </ng-container>
          <div class="col-span-2" *ngIf="company?.name">
            <h2 class="col-span-2 text-2xl font-bold my-4">Ansprechpartner</h2>
            <app-dynamic-form [id]="'company_contact'" [entityName]="'contacts'"
                              [entity]="companyContact"></app-dynamic-form>
            <h2 class="col-span-2 text-2xl font-bold my-4">Firmen anschrift</h2>
            <app-dynamic-form [id]="'company_address'"
                              [entityName]="'addresses'"
                              [entity]="companyAddress"
                              [firstRowFull]=false>
            </app-dynamic-form>
          </div>
          <div class="flex w-full flex-wrap col-span-2">
            <ion-button *ngIf="company && profile.role === 'admin'" shape="round" type="submit" color="primary"
                        fill="outline"
                        class="primary w-full uppercase">Firmenprofil speichern
            </ion-button>
            <ion-button *ngIf="!company" (click)="addCompanyProfile()" shape="round" color="primary" fill="outline"
                        class="primary w-full uppercase">Firmenprofil hinzufügen
            </ion-button>
          </div>
        </form>

        <div class="w-full grid grid-cols-2 gap-4 h-fit" *ngIf="profile?.role === 'member'">

        </div>
        <ng-container *ngIf="profile?.role === 'admin'">
          <ion-button shape="round" color="primary" data-desc="Nutzer einladen" id="invite-modal" expand="block"
                      class="flex items-center" (click)="inviteModalVisible = true">
            <ion-icon class="mr-2" name="add-outline" color="white"></ion-icon>
            Nutzer einladen
          </ion-button>
          <ion-modal class="ion-padding" [isOpen]="inviteModalVisible" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
            <ng-template>
              <div class="p-4">
                <h2 class="text-2xl font-bold w-full text-left">Nutzer Einladen</h2>
                <form [formGroup]="inviteForm" (ngSubmit)="inviteUsers()" novalidate class="w-full">
                  <div class="flex w-full flex-wrap my-4">
                    <div class="flex w-full flex-wrap my-4">
                      <label class="w-full font-bold">E-mail*</label>
                      <p class="w-full text-xs">Füge mehrere Nutzer hinzu indem du die E-mails mit einem Komma trennst!</p>
                      <input name="emails" required formControlName="emails" class="w-full" placeholder="E-mail"/>
                    </div>
                    <div class="flex w-full my-2 flex-wrap">
                      <ion-button shape="round" type="submit" color="primary" class="primary w-full uppercase"
                                  [disabled]="!inviteForm.valid">Nutzer einladen
                      </ion-button>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
          </ion-modal>

          <ng-container *ngIf="invitedContacts!.length > 0">
            <h2 class="col-span-2 text-2xl font-bold my-4">Eingeladene Nutzer</h2>
            <ng-container *ngFor="let invitedContact of invitedContacts">
              <div class="w-full grid grid-cols-3 my-2">
                <div>{{ invitedContact.first_name }} {{ invitedContact.last_name }}</div>
                <div class="flex justify-center">{{ invitedContact.email }}</div>
                <div class="flex justify-end">
                  <ion-icon style="color: red; pointer-events: auto"
                            (click)="deleteInvitedContact(invitedContact)"
                            name="trash-bin-outline"
                            class="m-0 h-6 w-6 cursor-pointer">
                  </ion-icon>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let invited of notAcceptedInvites">
              <div class="w-full grid grid-cols-3 my-2">
                <div class="flex justify-center">{{ invited.email }}</div>
                <div class="flex justify-end">
                  <ion-icon style="color: red; pointer-events: auto"
                            (click)="deleteInvited(invited)"
                            name="trash-bin-outline"
                            class="m-0 h-6 w-6 cursor-pointer">
                  </ion-icon>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="invitedContacts!.length <= 0 && notAcceptedInvites.length <= 0">
            <p>Du hast aktuell noch keine Nutzer eingeladen</p>
          </ng-container>

        </ng-container>
      </div>
    </div>
    <div class="mt-8 mb-4 flex justify-center w-full">
      <div class="flex max-w-64 flex-wrap justify-center">
        <h3 class="text-base font-bold text-center mb-4">Weitere Informationen</h3>
        <a href="/" class="h-6 text-primary underline text-center w-full">Impressum</a>
        <a href="/" class="h-6 text-primary underline text-center w-full">Datenschutz</a>
        <a href="/" class="h-6 text-primary underline text-center w-full">AGB</a>
        <ion-button type="button" color="primary" fill="clear" class="flex items-center w-56 mt-12">
          <ion-icon name="trash-bin-outline" class="mr-2 mb-2"></ion-icon>
          Konto löschen
        </ion-button>
        <p class="text-xs">Verison: </p>
      </div>
    </div>
  </div>

</ion-content>
<app-footer></app-footer>



