<ion-fab *ngIf="!onlyModals" horizontal="end" vertical="bottom" slot="fixed" class="mb-4 mr-2">
  <ion-fab-button routerDirection="root" color="primary">
    <ion-icon name="add-circle"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button color="secondary" data-desc="Einstellungen">
      <ion-icon name="settings-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="secondary" data-desc="Bestand hinzufügen" id="inventory-modal">
      <ion-icon name="layers-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="primary" data-desc="Neuer Ordner" id="folder-modal" expand="block">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="primary" data-desc="Neue Planung" id="open-modal" expand="block" (click)="openPlanningModal()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
<ion-modal #inventoryModal trigger="inventory-modal" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
  <ng-template>
    <div class="new_planing_container flex-col p-4">
      <h2 class="text-2xl font-bold w-full text-left">Neuen Bestand hinzufügen</h2>
      <form [formGroup]="newInventoryForm" (ngSubmit)="addInventory()" novalidate class="w-full">
        <div class="flex w-full flex-wrap my-4">
          <div class="flex w-full flex-wrap my-4">
            <label class="w-full font-bold">Name*</label>
            <input type="text" name="name" formControlName="name" class="w-full" placeholder="Name"/>
          </div>
          <div class="flex w-full flex-wrap my-4">
            <label class="w-full font-bold">Notiz</label>
            <input type="text" name="description" formControlName="description" class="w-full" placeholder="Notiz"/>
          </div>
          <div class="flex w-full flex-wrap my-4">
            <ion-checkbox name="share" formControlName="share" labelPlacement="end">Bestand mit deinem Team teilen?
            </ion-checkbox>
          </div>
          <div class="flex w-full my-2 flex-wrap">
            <ion-button shape="round" type="submit" color="primary" class="primary w-full uppercase"
                        [disabled]="!newInventoryForm.valid">Bestand hinzufügen
            </ion-button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</ion-modal>
<ion-modal id="add-folder-modal" #folderModal trigger="folder-modal" [initialBreakpoint]="1"
           [breakpoints]="[0, 1]">
  <ng-template>
    <div class="modal_container flex-col">
      <h2 class="text-2xl font-bold w-full text-left">Neuen Ordner erstellen</h2>
      <div class="w-full p-4">
        <app-dynamic-form
          [id]="'add-folder-form'"
          [entityName]="'folders'"
          [formInSingleColumn]="true"
          (formReady)="onPlanningFormReady($event)"
        ></app-dynamic-form>
        <div class="mt-5">
          <p *ngIf="!isSharedWithContacts && !isSharedWithCompany">Die Inhalte des Ordners sind nur für dich
            Sichtbar.</p>
          <p *ngIf="isSharedWithCompany">Dein ganzes Team hat zugriff auf die Inhalte dieses Ordners</p>
          <div *ngIf="isSharedWithContacts">
            <h3>Suche die Kontakte aus die Zugriff auf diesen Ordner haben sollen</h3>

            <div class="multi-select-dropdown relative" #multiSelectDropdown>
              <div id="dropdown-container"
                   class="select-trigger w-full p-2 pl-4 border border-gray-300 cursor-pointer rounded-full"
                   [ngClass]="{'rounded-lg': isDropdownOpen}"
                   (click)="toggleDropdown()">
                <div class="flex items-center justify-between">
                  <span>Kontakte auswählen</span>
                  <ion-icon name="chevron-down-outline" [class.rotate-180]="isDropdownOpen"></ion-icon>
                </div>
                <div *ngIf="isDropdownOpen"
                     class="dropdown-options w-full mt-1 bg-white z-50 max-h-60 overflow-y-auto">
                  <div *ngFor="let companyContact of companyContacts | keyvalue"
                       class="option p-2 pl-4 cursor-pointer flex items-center justify-between"
                       (click)="toggleSelectedContact(companyContact.key, $event)">
                    <span>{{ companyContact.value.contact.last_name }}
                      , {{ companyContact.value.contact.first_name }}</span>
                    <div class="tick-container h-5 w-5 flex items-center justify-center">
                      <img *ngIf="companyContact.value.isSelected" src="assets/icon/tick.svg" alt="tick"
                           class="h-4 w-4"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="selected-contacts mt-2">
              <div *ngFor="let contact of getSelectedContacts()"
                   class="selected-contact flex justify-between items-center p-2 mb-1 bg-gray-50 rounded-md">
                <span>{{ contact.contact.last_name }}, {{ contact.contact.first_name }}</span>
                <ion-icon name="close-circle" class="text-red-500 cursor-pointer"
                          (click)="removeSelectedContact(contact.id)"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ion-button shape="round"
                  color="primary"
                  data-desc="Save inventory"
                  expand="block"
                  class="flex items-center w-full"
                  (click)="createFolder()">
        <p>Ordner speichern</p>
      </ion-button>
    </div>
  </ng-template>
</ion-modal>
