import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DatePipe, NgIf } from '@angular/common';
import { Tables } from '../../../models/database.types';

@Component({
  selector: 'app-folder-card',
  templateUrl: './folder-card.component.html',
  standalone: true,
  imports: [IonicModule, DatePipe, NgIf],
  styleUrls: ['./folder-card.component.scss']
})
export class FolderCardComponent {
  @Input() folderName!: string;
  @Input() count: number = 0;
  @Input() creator: string = '';
  @Input() createdAt: string = '';
  @Input() showDelete: boolean = false;

  @Output() folderSelect = new EventEmitter<string>();
  @Output() folderDelete = new EventEmitter<string>();

  openFolder() {
    this.folderSelect.emit(this.folderName);
  }

  deleteFolder() {
    this.folderDelete.emit(this.folderName);
  }
}