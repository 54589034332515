<ion-header>
  <ion-toolbar color="dark">
    <ion-title>
      <div class="logo"  [routerLink]="'/home'">
        <ion-img src="/assets/logo.png"></ion-img>
      </div>
    </ion-title>
    <ion-buttons slot="start">
      <ion-button color="dark" *ngIf="back" (click)="window.history.back()">
        <ion-icon size="large" name="chevron-back-outline" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="presentPopover($event)">
        <ion-icon size="large" name="ellipsis-vertical-outline" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
