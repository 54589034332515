import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "./services/toast.service";
import {SupabaseService} from "./services/supabase/supabase.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  session = this.supabase.session

  constructor(
    private supabase: SupabaseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.supabase.authChanges(async (_, session) => {
      this.session = session;
    })
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params['type'] === 'recovery') {
        console.log(params['token'])
        await this.router.navigate(['/auth/password-reset'], {queryParams: {token: params['token']}});
      }
      if (params['type'] === 'signup') {
        const {data, error} = await this.supabase.confirmMail(params['token']);
        if (error) {
          await this.toastService.presentToast('Es ist ein unerwarteter Fehler aufgetreten. Versuche es erneut.', 5000)
        } else {
          await this.router.navigate(['/home']);
        }
      }
    });
  }
}
