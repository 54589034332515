import {Injectable} from '@angular/core'
import {createClient, SupabaseClient,} from '@supabase/supabase-js'
import {SupabaseService} from "./supabase.service";
import {environment} from "../../../environments/environment";
import {Tables} from "../../../models/database.types";

export interface Profile {
  id?: string
  username: string
  website: string
  avatar_url: string
}

@Injectable({
  providedIn: 'root',
})
export class SupabasePlanningService {
  private supabase: SupabaseClient

  constructor(
    private supasebaseService: SupabaseService
  ) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  async getPlannings() {
    return this.supabase.from('plannings')
      .select()
      .eq('creator_id', this.supasebaseService.session?.user.id)
      .order('created_at', {ascending: false})
  }

  async getPlanningById(id: string) {
    return this.supabase.from('plannings')
      .select()
      .eq('id', id)
      .single()
  }

  async getTeamPlannings(companyId: string, share = true) {
    return this.supabase.from('plannings')
      .select('*')
      .eq('share', share)
      .eq('company_id', companyId)
      .select()
  }

  async getPrivatePlanningsWithoutFolders() {
    return this.supabase.from('plannings')
      .select('*')
      .is('folder_id', null)
      .eq('creator_id', this.supasebaseService.session?.user.id);
  }

  /*
  async getPlanningsByFolder(folder: string) {
    return this.supabase.from('plannings')
      .select('*')
      .eq('folder', folder)
      .order('created_at', {ascending: false})
  }
   */

  async updatePlanning(data: any) {
    return this.supabase.from('plannings')
      .upsert({
        id: data.id,
        creator_id: this.supasebaseService.session?.user.id,
        name: data.name,
        description: data.description,
        share: data.share,
        folder_id: data.folder_id,
        company_id: data.company_id
      }).select()
  }

  async updatePlanningsFolder(plannings: Tables<'plannings'>[]) {
    return this.supabase.from('plannings')
      .upsert(plannings)
      .select()
  }

  /*
  async updatePlanningsShareStatusByFolder(folder: string, shareStatus: boolean) {
    return this.supabase.from('plannings')
      .update({ share: shareStatus})
      .eq('folder', folder)
      .select();
  }

  async getPlanningsAccessByFolder(folder: string) {
    return this.supabase.from('plannings_access')
      .select('*')
      .eq('folder', folder)
      .select()
  }

  async getPlanningsAccessById() {
    return this.supabase.from('plannings_access')
      .select('*')
      .eq('user_id', this.supasebaseService.session?.user.id)
      .select()
  }

  async updatePlanningAccess(planningsAccess: Tables<'plannings_access'>[]) {
    return this.supabase.from('plannings_access')
      .upsert(planningsAccess)
      .select();
  }
  */

  async deletePlanningsAccessByFolder(folder: string) {
    return this.supabase.from('plannings_access')
      .delete()
      .eq('folder', folder);
  }

  /*
  async deletePlanningsAccessByForUser(id: string, folder: string) {
    return this.supabase.from('plannings_access')
      .delete()
      .eq('user_id', id)
      .eq('folder', folder);
  }
   */
}
