import {Component, OnInit} from '@angular/core';
import {IonicModule, PopoverController} from "@ionic/angular";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {NgIf} from "@angular/common";
import {SupabaseService} from "../../services/supabase/supabase.service";

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  imports: [
    IonicModule,
    NgIf
  ],
  standalone: true
})
export class PopoverComponent implements OnInit {
  anonymous = false;

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private supabase: SupabaseService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.supabase.authChanges((_, session) => {
      this.anonymous = session?.user.is_anonymous ?? true;
    })
  }

  async openProfile() {
    await this.popoverController.dismiss()
    await this.router.navigate(['/profile'])
  }

  async logout() {
    if (!this.supabase.session?.user || this.supabase.session?.user.is_anonymous) {
      localStorage.clear();
      await this.authService.signOut();
    } else {
      await this.authService.signOut();
    }
    await this.popoverController.dismiss()
  }
}
