import {Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {IonicModule, ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SupabaseService} from "../../services/supabase/supabase.service";
import {SupabasePlanningService} from "../../services/supabase/supabase-planning.service";
import {SupabaseCrudService} from "../../services/supabase-crud.service";
import {KeyValuePipe, NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {UserService} from "../../services/supabase/user.service";
import {Tables, TablesInsert} from "../../../models/database.types";
import {DynamicFormComponent} from "../../auth/dynamic-form/dynamic-form.component";
import {ToastService} from "../../services/toast.service";
import {SupabaseFolderService} from "../../services/supabase/supabase-folder.service";
import {LoadingService} from "../../services/loading.service";
import {PlanningModalComponent} from "../../modals/planning-modal/planning-modal.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule,
    NgIf,
    NgStyle,
    KeyValuePipe,
    NgForOf,
    DynamicFormComponent,
    NgClass,
    PlanningModalComponent
  ],
  standalone: true
})
export class FooterComponent implements OnInit {
  @ViewChildren(DynamicFormComponent) forms!: QueryList<DynamicFormComponent>;
  @ViewChild('multiSelectDropdown') multiSelectDropdown?: ElementRef;
  @Input() onlyModals: boolean = false
  @Input() openNewPlanModal: boolean = false;

  currentProfile?: Tables<'profiles'>
  anonymous = false;

  companyContacts: Map<string, { contact: Tables<'contacts'>, isSelected: boolean }> = new Map();
  isSharedWithContacts = false
  isSharedWithCompany = false
  isDropdownOpen = false
  folderModalOpen = false;

  newInventoryForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    share: [false]
  })

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    private supabase: SupabaseService,
    private planningService: SupabasePlanningService,
    private supabaseCrudService: SupabaseCrudService,
    private userService: UserService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private folderService: SupabaseFolderService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.supabase.authChanges(async (_, session) => {
      this.anonymous = session?.user.is_anonymous ?? true;
      if (!this.anonymous) {
        let resProfile = await this.userService.profile(session?.user!)
        this.currentProfile = resProfile?.data
        if (this.currentProfile) await this.fetchCompanyContacts()
      }
    })
  }

  async fetchCompanyContacts() {
    if (this.currentProfile && this.currentProfile.company_id) {
      const {data, error} = await this.supabase.getContactsByCompanyId(this.currentProfile.company_id)
      if (data) {
        const uniqueContactsArray =
          [...new Set(data.map((contact: Tables<'contacts'>) => contact.id))]
            .filter(p => p !== null);
        uniqueContactsArray.forEach((contactId) => {
          if (contactId !== this.currentProfile?.contact_id) {
            this.companyContacts.set(contactId, {
              contact: data.find(contact => contact.id === contactId)!,
              isSelected: false
            });
          }
        })
      }
      if (error) {
        console.error(error)
        await this.toastService.presentToast('Beim Laden der Firmenkontakte ist etwas scheifgelaufen', 5000)
      }
    }
  }

  async fetchPlanningFolders() {
    try {
      const user = this.supabase.session?.user;
      if (!user) throw 'No user session found'

      const profileResponse = await this.userService.profile(user);
      if (profileResponse.error) throw profileResponse.error;

      const userProfile = profileResponse.data;
      if (!userProfile?.company_id) throw 'No company_id found'
      this.currentProfile = userProfile;

      return await this.planningService.getTeamPlannings(userProfile.company_id);
    } catch (error) {
      console.error('Error in ngOnInit:', error);
      return null
    }
  }


  async addInventory() {
    try {
      const newInventory = {
        name: this.newInventoryForm.value.name,
        description: this.newInventoryForm.value.description,
        share: this.newInventoryForm.value.share,
      };
      const created = await this.supabaseCrudService.create('inventories', newInventory);
    } catch (error) {
      console.error('Failed to add inventory:', error);
    }
  }

  onPlanningFormReady(form: FormGroup) {
    if (form) {
      const shareCompanyControl = form.get('share_company');
      const shareContactsControl = form.get('share_contacts');

      if (shareCompanyControl && shareContactsControl) {
        shareCompanyControl.valueChanges.subscribe(value => {
          this.isSharedWithCompany = value
          if (this.isSharedWithCompany) {
            shareContactsControl.setValue(!value)

          }
        })
        shareContactsControl.valueChanges.subscribe(value => {
          this.isSharedWithContacts = value
          if (this.isSharedWithContacts) shareCompanyControl.setValue(!value)
        })
      }
    }
  }

  toggleDropdown() {
    const element = document.getElementById('dropdown-container')
    if (element) {
      if (element.classList.contains('rounded-full') && !this.isDropdownOpen) element.classList.add('rounded-lg')
      if (element.classList.contains('rounded-lg') && this.isDropdownOpen) element.classList.add('rounded-full')
    }
    setTimeout(() => this.isDropdownOpen = !this.isDropdownOpen, 250)
  }

  toggleSelectedContact(contactId: string, event: MouseEvent) {
    event.stopPropagation();
    const contactSelected = this.companyContacts.get(contactId);
    if (contactSelected) {
      this.companyContacts.set(contactId, {
        contact: contactSelected.contact,
        isSelected: !contactSelected.isSelected
      });
    }
  }

  getSelectedContacts(): Array<{ contact: Tables<'contacts'>, isSelected: boolean, id: string }> {
    const selectedContacts: Array<{ contact: Tables<'contacts'>, isSelected: boolean, id: string }> = [];
    this.companyContacts.forEach((value, key) => {
      if (value.isSelected) {
        selectedContacts.push({...value, id: key});
      }
    });
    return selectedContacts;
  }

  removeSelectedContact(contactId: string) {
    const contactSelected = this.companyContacts.get(contactId);
    if (contactSelected) {
      this.companyContacts.set(contactId, {
        contact: contactSelected.contact,
        isSelected: false
      });
    }
  }

  // TODO: add after adding the folder element directly in UI!
  async createFolder() {
    await this.loadingService.show()
    const entityForm = this.forms.toArray()
      .find(formAttr => formAttr.id === 'add-folder-form')
    if (!entityForm || !entityForm.form.touched) {
      await this.toastService.presentToast('Bitte fülle alle Felder aus', 5000)
      return
    }
    if (!entityForm.form.value.name || entityForm.form.value.name === '') {
      await this.toastService.presentToast('Der Ordner benötigt einen Namen', 5000)
      return
    } else {
      const folder: TablesInsert<'folders'> = {
        name: entityForm.form.value.name,
        share_company: entityForm.form.value.share_company ?? false,
        share_contacts: entityForm.form.value.share_contacts ?? false,
        company_id: this.currentProfile!.company_id,
        creator_id: this.currentProfile?.id
      }
      const {data, error} = await this.folderService.insertFolder(folder)
      if (error) {
        console.error(error)
        await this.toastService.presentToast('Beim Speichern des Ordners ist etwas schiefgelaufen.', 5000)
        return
      }
      if (data && data.length > 0) {
        const selectedContacts = this.getSelectedContacts().map(c => c.contact);
        for (const contact of selectedContacts) {
          const folderContactRelation = {
            folder_id: data[0].id,
            profile_id: contact.profile_id
          }
          await this.folderService.updateFolderContactRelation(folderContactRelation)
        }
      }
      const folderModal = document.getElementById('add-folder-modal') as HTMLIonModalElement
      if (folderModal) await folderModal.dismiss()
    }
    await this.loadingService.hide()
  }

  async openPlanningModal() {
    const modal = await this.modalController.create({
      component: PlanningModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        profile: this.currentProfile
      }
    });

    modal.present();

    const {data, role} = await modal.onWillDismiss();

  }
}
