<app-header [back]="true"></app-header>
<ion-content [fullscreen]="true">
  <div class="p-8 pb-0 w-full">
    <div class="flex items-center justify-start gap-5"
         [ngClass]="{'mb-8': !editFolder, 'mb-2': editFolder}">
      <ng-container *ngIf="editFolder">
        <input class="text-3xl font-bold border border-gray-400 rounded-full w-1/2" type="text"
               [(ngModel)]="folderName"/>
      </ng-container>
      <ng-container *ngIf="!editFolder && folderName !== 'private-plannings' && folderName !== 'shared-plannings'">
        <h1 class="text-3xl font-bold">{{ folderName }}</h1>
      </ng-container>
      <ng-container *ngIf="folderName === 'private-plannings'">
        <h1 class="text-3xl font-bold">Deine Planungen</h1>
      </ng-container>
      <ng-container *ngIf="folderName === 'shared-plannings'">
        <h1 class="text-3xl font-bold">Planungen von deinem Team.</h1>
      </ng-container>
      <img *ngIf="folderName !== '' && isCreator && folderName !== 'private-plannings'" alt="edit folder" src="assets/icon/pencil-solid.svg"
           (click)="editFolder = !editFolder"/>
    </div>
    <ng-container *ngIf="!isCreator">
      Du kannst keine Einstellungen an diesem Ordner vornehmen, da du diesem Ordner nicht erstellt hat.
    </ng-container>

    <div *ngIf="editFolder" class="border-b border-gray-400 py-5 pb-5 relative">
      <div class="pl-3 flex flex-col items-start">
        <div class="flex gap-3 items-center justify-start">
          <input id="share-with-none" type="radio" name="share-radio" class="h-fit p-0 custom-radio"
                 [checked]="!folder?.share_contacts && !folder?.share_company"
                 (change)="handleSharing($event)">
          <label for="share-with-contacts">Ordner privat speicher</label>
        </div>
        <div class="flex gap-3 items-center justify-start">
          <input id="share-with-company" type="radio" name="share-radio" class="h-fit p-0 custom-radio"
                 [checked]="folder?.share_company"
                 (change)="handleSharing($event)">
          <label for="share-with-company">Ordner mit der Firma teilen</label>
        </div>
        <div class="flex gap-3 items-center justify-start">
          <input id="share-with-contacts" type="radio" name="share-radio" class="h-fit p-0 custom-radio"
                 [checked]="folder?.share_contacts"
                 (change)="handleSharing($event)">
          <label for="share-with-contacts">Ordner mit Kontakten teilen</label>
        </div>
      </div>
      <div *ngIf="folder?.share_contacts" class="pl-3 flex flex-col mt-5">
        <h2 class="font-bold">Kontakte auswählen und zu diesem Ordner hinzufügen</h2>
        <select class="rounded-md" (change)="handleAddContactAccess($event)">
          <option value="default" [defaultSelected]="'Kontakt auswählen'">Kontakt auswählen</option>
          <option *ngFor="let companyProfile of companyProfiles"
                  [value]="companyProfile.id">
            {{ companyProfile.first_name }} {{ companyProfile.last_name }}
          </option>
        </select>
        <h2 class="col-span-2 font-bold my-4 mb-2">Nutzer mit Zugang zu diesem Ordner</h2>
        <ng-container *ngFor="let folderContact of profilesAddedToFolder">
          <div class="w-full flex gap-2 my-2">
            <div>{{ folderContact.first_name }} {{ folderContact.last_name }}</div>
            <!--<div class="flex justify-center">{{ folderContact.email }}</div>-->
            <div class="flex justify-end">
              <ion-icon style="color: red; pointer-events: auto"
                        (click)="handleRemoveContactAccess(folderContact)"
                        name="trash-bin-outline"
                        class="m-0 h-6 w-6 cursor-pointer">
              </ion-icon>
            </div>
          </div>
        </ng-container>
        <span class="col-span-2 w-fit mt-0" *ngIf="profilesAddedToFolder.length === 0">
            Du hast noch keine Kontakte zu dem Ordner hinzugefügt.
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="foldersOfCreatorMap.size > 0">
    <div class="p-4 lg:p-8 lg:pt-0 pt-0">
      <div class="px-4 py-8 w-full h-fit rounded-xl shadow-lg bg-[#f7f7f7]">
        <div class="flex gap-3 items-center mb-5 w-fit cursor-pointer">
          <h2 class="text-xl font-bold">Ordner</h2>
        </div>
        <div class="grid grid-cols-4 gap-6">
          <app-folder-card
            *ngFor="let folder of foldersOfCreatorMap | keyvalue"
            [folderName]="folder.key"
            [count]="folder.value.count"
            [creator]="folder.value.creator"
            [createdAt]="folder.value.createdAt"
            [showDelete]="true"
            (folderSelect)="routeToPlannings($event)"
            (folderDelete)="routeToPlannings($event)">
          </app-folder-card>

          <!--        <app-planning-card
                    *ngFor="let planning of planningsOfFolder; let i = index"
                    [planning]="planning"
                    [planningIdx]="i"
                    [showDelete]="true"
                    [showMove]="true"
                    [folders]="folders"
                    [showMovePlanningPopup]="showMovePlanningPopup && planningIdx === i"
                    (planningSelect)="openPlanning($event)"
                    (planningDelete)="deletePlanning($event)"
                    (planningMoveToggle)="handleMovePlanningToggle($event)"
                    (planningMove)="handlePlanningMove($event)">
                  </app-planning-card>-->
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="folderName !== ''">
    <div class="p-4 lg:p-8 lg:pt-0 pt-0">
      <div class="px-4 py-8 w-full h-fit rounded-xl shadow-lg bg-[#f7f7f7]">
        <div class="flex gap-3 items-center mb-5 w-fit cursor-pointer">
          <h2 class="text-xl font-bold">Planungen</h2>
        </div>
        <ng-container *ngIf="plannings.length <= 0">
          <ion-chip class="w-fit" color="warning">
            Du hast noch keine Planung erstellt.
          </ion-chip>
        </ng-container>
        <div class="grid grid-cols-4 gap-6">
          <app-planning-card (planningSelect)="openPlanning($event)"
                             *ngFor="let planning of plannings" [planning]="planning"></app-planning-card>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="m-5 w-[calc(100% - 40px)]">
    <ion-button shape="round"
                color="primary"
                data-desc="Add planing"
                expand="block"
                class="flex items-center mt-5"
                (click)="newPlanning()">
      <ion-icon class="mr-2" name="add-outline"></ion-icon>
      <p>Neue Planung hinzufügen.</p>
    </ion-button>
    <ion-button *ngIf="profile?.role === 'admin' || isCreator"
                shape="round"
                color="primary"
                fill="outline"
                data-desc="Save inventory"
                expand="block"
                class="flex items-center"
                (click)="handleSubmit()"
                [disabled]="!editFolder"
                [ngClass]="{'opacity-50': !editFolder}">
      <p>Änderungen für diesen Ordner speichern.</p>
    </ion-button>
  </div>
</ion-content>
