import {Injectable} from '@angular/core'
import {LoadingController, ToastController} from '@ionic/angular'
import {AuthChangeEvent, createClient, Session, SupabaseClient} from '@supabase/supabase-js'
import {environment} from '../environments/environment'
import {Tables, TablesInsert, TablesUpdate} from "../types/supabase";
import {OfferStatus} from "./utils/constants";

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  get user() {
    return this.supabase.auth.getUser().then(({data}) => data?.user)
  }

  get session() {
    return this.supabase.auth.getSession().then(({data}) => data?.session)
  }

  get profile() {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('profiles').select(`*`).eq('id', id).single()
      )
  }

  get contact() {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('contact').select(`*`).eq('id', id).single()
      )
  }

  get address() {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('address').select(`*`).eq('id', id).single()
      )
  }

  findAddress(id: string) {
    return this.supabase.from('address').select(`*`).eq('id', id).single()
  }

  findOfferAddress(id: string) {
    return this.supabase.from('offer_address').select(`*`).eq('id', id).single()
  }

  findProduct(id: string) {
    return this.supabase.from('products').select(`*`).eq('id', id).single()
  }

  get products() {
    return this.supabase.from('products').select();
  }

  findOfferedProducts() {
    return this.user
      .then((user) => user?.id)
      .then((id) => {
        return this.supabase.from('offered_products').select('*').eq('user_id', id);
      });
  }

  async removeAllProductsForUser(userId: string) {
    return this.supabase.from('offered_products').delete().eq('user_id', userId);
  }

  get offers() {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('offer').select(`*`).eq('customer', id).select()
      )
  }

  get searchs() {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('offer').select(`*`)
          .eq('delivery', true)
          .eq('status', 'OPEN')
          .lte('available_from', new Date().toDateString())
          .gte('available_to', new Date().toDateString())
          .select()
      )
  }


  offer(offerId: string) {
    return this.user
      .then((user) => user?.id)
      .then((id) =>
        this.supabase.from('offer').select(`*`).eq('id', offerId).select()
      )
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  signIn(email: string) {
    return this.supabase.auth.signInWithOtp({email})
  }

  signInWithPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({email, password})
  }

  restPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email)
  }

  signOut() {
    return this.supabase.auth.signOut()
  }

  async updateProfile(profile: TablesUpdate<'profiles'>) {
    const update = {
      ...profile,
      updated_at: new Date(),
    }

    return this.supabase.from('profiles').upsert(update)
  }

  async updateAddress(address: TablesUpdate<'address'>) {
    const update = {
      ...address,
      updated_at: new Date(),
    }
    return this.supabase.from('address').upsert(update)
  }

  async updateOfferAddress(address: TablesUpdate<'offer_address'>) {
    const update = {
      ...address,
      updated_at: new Date(),
    }
    return this.supabase.from('offer_address').upsert(update).select().single()
  }


  async createOffer(offer: TablesUpdate<'offer'>) {
    return this.supabase.from('offer').insert(offer).select();
  }

  createOfferHistory(offerHistory: TablesInsert<"offer_history">) {
    return this.supabase.from('offer_history').insert(offerHistory).select();
  }

  async updateOffer(offer: TablesUpdate<'offer'>) {
    const update = {
      ...offer,
      updated_at: new Date(),
    }
    return this.supabase.from('offer').upsert(offer).select().single();
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path)
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file)
  }

  async createNotice(message: string) {
    const toast = await this.toastCtrl.create({message, duration: 5000})
    await toast.present()
  }

  createLoader() {
    return this.loadingCtrl.create()
  }

  updateUserPW(email: string, password: string) {
    this.supabase.auth.updateUser({email, password}).then(res => console.log(res)).catch(e => console.log(e))
  }

  async signUp(email: string, password: string) {
    return this.supabase.auth.signUp({email, password})
  }

  async updateContact(contact: TablesUpdate<"contact">) {
    const update = {
      ...contact,
      updated_at: new Date(),
    }

    return this.supabase.from('contact').upsert(update)
  }

  async addOfferedProductsForUser(productMap: Map<string, string>, id: string) {
    const p: TablesInsert<'offered_products'>[] = [];
    productMap.forEach(pro => {
      p.push({
        user_id: id,
        product_id: pro
      });
    })
    return this.supabase.from('offered_products').upsert(p);
  }

  async offersForOfferedProducts(data: Tables<'offered_products'>[]) {
    return this.supabase.from('offer').select()
      .in('product', data.map(e => e.product_id))
      .eq('status', 'OPEN')
      .lte('available_from', new Date().toDateString())
      .gte('available_to', new Date().toDateString())
  }

  async getMyPendingOffers() {
    return this.user
      .then((user) => user?.id)
      .then((id) => {
        return this.supabase.from('offer').select()
          .eq('status', OfferStatus.ACCEPTED)
          .eq('provider', id);
      })
  }

  async getMyClosedOffers() {
    return this.user
      .then((user) => user?.id)
      .then((id) => {
        return this.supabase.from('offer').select()
          .eq('status', OfferStatus.CLOSED)
          .eq('provider', id);
      })
  }
}
