import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SupabaseClient} from "@supabase/supabase-js";
import {SupabaseService} from "../../services/supabase/supabase.service";
import {IonicModule} from "@ionic/angular";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {messages} from "../../../assets/messages";

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    NgClass
  ],
  standalone: true
})
export class DynamicFormComponent implements OnInit {
  @Input() id!: string;
  @Input() entityName: string = '';
  @Input() entity: any
  @Input() formOrder: string[] = [];
  @Input() firstRowFull: boolean = true;
  @Input() formInSingleColumn: boolean = false;
  @Input() disableColumns: boolean = true;

  @Output() formReady = new EventEmitter<FormGroup>();

  isAdmin = true
  form: FormGroup = this.fb.group({});
  formControls: any[] = [];
  supabase?: SupabaseClient;

  constructor(
    private supabaseService: SupabaseService,
    private fb: FormBuilder
  ) {
    this.supabase = this.supabaseService.getSupabase();
  }

  async ngOnInit() {
    if (this.entityName) {
      await this.createDynamicForm();
      if (this.entity && this.form) this.form.patchValue(this.entity);
      setTimeout(() => {
        this.formReady.emit(this.form);
      }, 0);
    }

    this.supabaseService.authChanges(async (_, session) => {
      if (session) {
        const {user} = session;
        const resRole = await this.supabase?.from('profiles').select('role').eq('id', user.id).single()
        const role = resRole?.data ? resRole.data.role : ''
        this.isAdmin = !!user && role === 'admin';
        if (!this.isAdmin && this.form && this.formControls.length > 0 && this.disableColumns) {
          if ((this.id === 'profile' || this.id === 'profile_address') && this.form.controls['email']) {
            //  this.form.controls['email'].disable()
          } else {
/*            this.formControls.forEach(control => {
              this.form.controls[control.name].disable()
            })*/
          }
        }
      }
    })
  }

  async createDynamicForm(): Promise<void> {
    const fields = await this.getTableStructure();
    const customOrder = this.formOrder.length <= 0
      ? fields.map((field: any) => field.column_name)
      : this.formOrder;
    fields.forEach((field: any) => {
      if (this.checkIfValidFormInput(field.column_name)) {
        const fieldPriority = customOrder.indexOf(field.column_name);
        const priority = fieldPriority === -1 ? customOrder.length : fieldPriority;
        const control = field.is_nullable === 'NO'
          ? this.fb.control(null, [Validators.required])
          : this.fb.control(null)
        this.formControls.push({
          name: field.column_name,
          type: field.data_type,
          control: control,
          priority: priority
        });
      }
    });
    this.formControls.sort((a, b) => a.priority - b.priority);
    let group = this.fb.group({})
    this.formControls.forEach(control => {
      group.addControl(control.name, control.control)
    });
    this.form = group;
  }

  async getTableStructure() {
    const {data, error} = this.entityName === 'profiles'
      ? await this.supabase!.rpc('get_table_columns_profile_contact', {entity_name: this.entityName})
      : await this.supabase!.rpc('get_table_columns', {entity_name: this.entityName});
    if (error) {
      console.error('Error fetching columns:', error);
      return [];
    }
    return data;
  }

  checkIfValidFormInput(column: string) {
    return !column.includes('created_')
      && !column.includes('updated_')
      && !column.includes('creator_')
      && !column.includes('id')
      && !column.includes('username')
      && !column.includes('avatar_url')
      && !column.includes('role')
      && !column.includes('type')
      && !column.includes('floor_plan')
      && !column.includes('folder')
  }

  getValidators(dataType: string) {
    if (dataType.includes('character') || dataType.includes('text')) return [Validators.required];
    if (dataType.includes('int') || dataType.includes('numeric')) return [Validators.required, Validators.pattern('^[0-9]+$')];
    return [];
  }

  getInputType(control: any) {
    return control.validator && control.validator.toString().includes('pattern') ? 'number' : 'text';
  }

  // TODO: Maybe submit callback needed ?
  async submitForm() {
  }

  protected readonly messages = messages;
  protected readonly JSON = JSON;
}
