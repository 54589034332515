<ng-container *ngIf="formControls.length > 0">
  <form [formGroup]="form" (ngSubmit)="submitForm()"
        [ngClass]="{'grid-cols-2' : !formInSingleColumn, 'grid-cols-1': formInSingleColumn}"
        class="grid gap-4">
    <ng-container *ngFor="let field of formControls; let idx = index;">
      <ng-container *ngIf="firstRowFull">
        <ng-container *ngIf="idx === 0">
          <div class="col-span-2">
            <div class="flex w-1/2 flex-wrap pr-2" [ngClass]="{'w-full': formInSingleColumn}">
              <label class="w-full font-bold">{{ messages[field.name] || field.name }}</label>
              <ng-container *ngIf="field.type === 'boolean'">
                <ion-checkbox name="share" [formControlName]="field.name" labelPlacement="end">
                  {{ field.name === 'share_contact' ? 'Einzelne Kontakte zum Ordner hinzufügen' : 'Mit deinem Team teilen?' }}
                </ion-checkbox>
              </ng-container>
              <ng-container *ngIf="field.type !== 'boolean'">
                <input [type]="getInputType(field.control)" [formControlName]="field.name"
                       class="w-full"/>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="idx > 0">
          <div class="flex w-full flex-wrap" [ngClass]="{'col-span-2': formInSingleColumn}">
            <label class="w-full font-bold">{{ messages[field.name] || field.name }}</label>
            <ng-container *ngIf="field.type === 'boolean'">
              <ion-checkbox name="share" [formControlName]="field.name" labelPlacement="end">
                {{ field.name === 'share_contacts' ? 'Einzelne Kontakte zum Ordner hinzufügen' : 'Mit deinem Team teilen?' }}
              </ion-checkbox>
            </ng-container>
            <ng-container *ngIf="field.type !== 'boolean'">
              <input [type]="getInputType(field.control)" [formControlName]="field.name"
                     class="w-full"/>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!firstRowFull">
        <div class="flex w-full flex-wrap ">
          <label class="w-full font-bold">{{ messages[field.name] || field.name }}</label>
          <ng-container *ngIf="field.type === 'boolean'">
            <ion-checkbox name="share" [formControlName]="field.name" labelPlacement="end">
              {{ field.name === 'share_contact' ? 'Einzelne Kontakte zum Ordner hinzufügen' : 'Mit deinem Team teilen?' }}
            </ion-checkbox>
          </ng-container>
          <ng-container *ngIf="field.type !== 'boolean'">
            <input [type]="getInputType(field.control)" [formControlName]="field.name"
                   class="w-full"/>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </form>
</ng-container>
