import {Injectable} from '@angular/core';
import {createClient, SupabaseClient} from '@supabase/supabase-js';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupabaseCrudService {
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  // 🔹 Handle errors in one place
  private handleError(error: any, operation: string) {
    console.error(`❌ Supabase ${operation} Error:`, error.message || error);
    throw new Error(`Error in ${operation}: ${error.message || error}`);
  }

  // ✅ Generic CREATE function
  async create<T>(table: string, data: T): Promise<T | null> {
    try {
      const {data: result, error} = await this.supabase.from(table).insert([data]).select();
      if (error) this.handleError(error, `Create (${table})`);
      return result ? result[0] : null;
    } catch (error) {
      this.handleError(error, `Create (${table})`);
      return null;
    }
  }

  // ✅ Generic READ function (Get All with Optional Filters)
  async getAll<T>(table: string, filters?: { column: string; value: any }[]): Promise<T[]> {
    try {
      let query = this.supabase.from(table).select('*');

      // Apply filters if provided
      if (filters) {
        filters.forEach((filter) => {
          query = query.eq(filter.column, filter.value);
        });
      }

      const {data, error} = await query;
      if (error) this.handleError(error, `Get All (${table})`);
      return data || [];
    } catch (error) {
      this.handleError(error, `Get All (${table})`);
      return [];
    }
  }

  // ✅ Generic READ function (Get by ID)
  async getById<T>(table: string, id: string): Promise<T | null> {
    try {
      const {data, error} = await this.supabase.from(table).select('*').eq('id', id).single();
      if (error) this.handleError(error, `Get By ID (${table})`);
      return data || null;
    } catch (error) {
      this.handleError(error, `Get By ID (${table})`);
      return null;
    }
  }

  // ✅ Generic UPDATE function
  async update<T>(table: string, id: string, data: Partial<T>): Promise<T | null> {
    try {
      const {data: result, error} = await this.supabase.from(table).update(data).eq('id', id).select();
      if (error) this.handleError(error, `Update (${table})`);
      return result ? result[0] : null;
    } catch (error) {
      this.handleError(error, `Update (${table})`);
      return null;
    }
  }

  // ✅ Generic DELETE function
  async delete(table: string, id: string): Promise<boolean> {
    try {
      const {error} = await this.supabase.from(table).delete().eq('id', id);
      if (error) this.handleError(error, `Delete (${table})`);
      return true;
    } catch (error) {
      this.handleError(error, `Delete (${table})`);
      return false;
    }
  }
}
