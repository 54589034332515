import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, PopoverController} from "@ionic/angular";
import {NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {PopoverComponent} from "./popover/popover.component";
import {SupabaseService} from "../services/supabase/supabase.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    IonicModule,
    NgIf,
    RouterLink
  ],
  standalone: true
})
export class HeaderComponent implements OnInit {
  @Input() back = false;
  readonly window = window;
  anonymous = false;

  constructor(
    private supabase: SupabaseService,
    private popoverController: PopoverController,
  ) {
  }

  ngOnInit() {
    this.supabase.authChanges((_, session) => {
      this.anonymous = session?.user.is_anonymous ?? true;
    })
  }

  async presentPopover(e: MouseEvent) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: e,
    });
    await popover.present();
    const {role} = await popover.onDidDismiss();
  }
}
