import {Component, OnInit} from '@angular/core';
import {IonicModule, ModalController} from "@ionic/angular";
import {HeaderComponent} from "../header/header.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Tables} from "../../models/database.types";
import {SupabasePlanningService} from "../services/supabase/supabase-planning.service";
import {DatePipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {UserService} from "../services/supabase/user.service";
import {SupabaseService} from "../services/supabase/supabase.service";
import {ToastService} from "../services/toast.service";
import {SupabaseFolderService} from "../services/supabase/supabase-folder.service";
import {FooterComponent} from "../home/footer/footer.component";
import {Session} from "@supabase/supabase-js";
import {LoadingService} from "../services/loading.service";
import {PlanningModalComponent} from "../modals/planning-modal/planning-modal.component";
import {PlanningCardComponent} from "../components/planning-card/planning-card.component";
import {FolderCardComponent} from "../components/folder-card/folder-card.component";

@Component({
  selector: 'app-plannings',
  templateUrl: './plannings.component.html',
  styleUrls: ['./plannings.component.scss'],
  imports: [
    IonicModule,
    HeaderComponent,
    FooterComponent,
    NgIf,
    NgForOf,
    FormsModule,
    NgClass,
    KeyValuePipe,
    DatePipe,
    PlanningCardComponent,
    FolderCardComponent
  ],
  standalone: true
})
export class PlanningsComponent implements OnInit {
  private session: Session | null = null;
  anonymous = false;

  folders: Tables<'folders'>[] = []
  plannings: Tables<'plannings'>[] = []

  folder?: Tables<'folders'>
  folderName = ''

  foldersOfCreatorMap: Map<string, { count: number, creator: string, createdAt: string }> = new Map();

  profile?: Tables<'profiles'>
  companyProfiles: Tables<'profiles'>[] = []
  profilesAddedToFolder: Tables<'profiles'>[] = []


  editFolder = false
  showMovePlanningPopup = false;
  planningIdx = -1
  isCreator = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private planningService: SupabasePlanningService,
    private userService: UserService,
    private supabase: SupabaseService,
    private folderService: SupabaseFolderService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private router: Router,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.supabase.authChanges(async (_, session) => {
      if (session) {
        this.session = session;
        this.anonymous = session?.user.is_anonymous ?? true;
      }
    })
  }

  async ionViewDidEnter() {
    if (!this.anonymous) {
      let resProfile = await this.userService.profile(this.session?.user!)
      this.profile = resProfile?.data
      this.folderName = this.activatedRoute.snapshot.params['folder'];
      if (this.folderName === 'private-plannings') {
        await this.loadPrivateItems()
        this.isCreator = true;
      } else if (this.folderName === 'shared-plannings') {
        await this.loadSharedItems()
      } else {
        await this.loadFolderItems()
      }
      await this.fetchCompanyContacts()
    }
  }

  async fetchCompanyContacts() {
    if (this.profile && this.profile.company_id) {
      const {data, error} = await this.supabase.getProfilesByCompanyId(this.profile.company_id)
      if (error) {
        await this.toastService.presentToast('Beim Laden der Firmenkontakte ist etwas scheifgelaufen', 5000)
      }
      if (data) {
        this.companyProfiles = data.filter(p => p.id !== this.profile?.id)
      }
    }
  }

  async loadPrivateItems() {
    this.planningService.getPrivatePlanningsWithoutFolders()
      .then(res => {
        this.plannings = res.data ?? []
      })
      .catch(err => {
        this.toastService.presentToast('Deine privaten Planungen konnten nicht geladen werden.', 5000);
      });
    this.folderService.getPrivateCreatorFolders()
      .then(res => {
        if (res.data) {
          for (const folder of res.data) {
            this.foldersOfCreatorMap.set(folder.name, {
              count: 0,
              creator: `${this.profile ? `${this.profile.first_name}, ${this.profile.last_name}` : ''}`,
              createdAt: folder.created_at
            })
          }
        }
      })
      .catch(err => {
        this.toastService.presentToast('Deine privaten Planungen konnten nicht geladen werden.', 5000);
      })
  }

  async loadSharedItems() {
    const {data, error} = await this.folderService.getCompanyFolders(this.profile!.company_id!);
    if (error) {
      await this.toastService.presentToast('Beim Laden der Ordner ist etwas schiefgelaufen! Bitte versuche es später nochmal.', 5000)
      return
    }
    if (data) {
      for (const folder of data) {
        if ((folder.share_company || folder.share_contacts) && folder.creator_id === this.profile!.id) {
          this.foldersOfCreatorMap.set(folder.name, {
            count: 0,
            creator: '',
            createdAt: folder.created_at
          })
        }
        const resFolderProfileRel = await this.folderService.getFoldersOfProfileByFolderId(folder.id)
        if (folder.share_contacts && resFolderProfileRel.data && resFolderProfileRel.data.length > 0) {
          const profileHasAccess = resFolderProfileRel.data.filter(i => i.profile_id === this.profile?.id)
          if (profileHasAccess.length > 0) {
            this.plannings.push(folder)
          }
        }
        if (folder.share_company && folder.creator_id !== this.profile?.id) {
          this.plannings.push(folder)
        }
      }
    }
  }

  async loadFolderItems() {
    if (this.profile?.company_id) {
      const folderRes = await this.folderService.getFolderByName(this.folderName)
      if (folderRes.data) {
        this.folder = folderRes.data
        this.isCreator = this.profile?.id === this.folder?.creator_id
      }
      const planningsRes = await this.folderService.getPlanningCountByFolder([folderRes.data.id])
      this.plannings = planningsRes.data ?? []
    }
  }

  async handleRemoveContactAccess(contact: Tables<'profiles'>) {

  }

  handleSharing(event: Event) {
    const input = event.target as HTMLInputElement;
    if (this.folder) {
      this.folder.share_company = input.id.endsWith('company')
      this.folder.share_contacts = input.id.endsWith('contacts')
      if (input.id.endsWith('none')) {
        this.folder.share_contacts = false
        this.folder.share_company = false
      }
    }
  }

  handleAddContactAccess(event: Event) {
    const select = event.target as HTMLSelectElement;
    const contact = this.companyProfiles.find(c => c.id === select.value);
    if (contact && !this.profilesAddedToFolder.some(c => c.id === contact.id)) {
      this.profilesAddedToFolder.push(contact);
      this.companyProfiles = this.companyProfiles.filter(c => c.id !== contact.id);
      select.value = 'default';
    }
  }

  async handleSubmit() {
    await this.loadingService.show()
    if (this.folder) {
      this.folder!.name = this.folderName
      if (this.folder.share_contacts) {
        if (this.profilesAddedToFolder.length === 0) {
          await this.toastService.presentToast('Du hast noch keine Nutzer zu diesem Ordner hinzugefügt', 5000)
          return
        }
        for (const addedProfile of this.profilesAddedToFolder) {
          const folderProfileRel = {
            folder_id: this.folder.id,
            profile_id: addedProfile.id
          }
          await this.folderService.updateFolderContactRelation(folderProfileRel)
            .then(res => console.log(res))
        }
      }
      if ((!this.folder.share_contacts && !this.folder.share_company) || this.folder.share_company) {
        await this.folderService.deleteFolderProfileRelByFolderId(this.folder.id)
          .then(res => console.log(res))
      }
      await this.folderService.updateFolder(this.folder)
        .then(res => {
          if (res.data) {
            this.folder = res.data[0] ?? null;
            this.editFolder = false;
          }
        })
        .catch(err => console.error(err))
    }
    await this.loadingService.hide()
  }

  async openPlanning(planning: Tables<'plannings'>) {
    await this.router.navigate(
      ['/start', {outlets: {start: 'settings'}}],
      {queryParams: {id: planning.id}}
    )
  }


  async newPlanning() {
    const modal = await this.modalController.create({
      component: PlanningModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        profile: this.profile,
        selectedFolder: this.folder?.name,
        isInFolder: true,
      }
    });

    modal.present();

    const {data, role} = await modal.onWillDismiss();
  }

  async routeToPlannings(routeOption: string) {
    await this.router.navigate(['/plannings/' + routeOption])
  }

  async deletePlanning(planning: Tables<'plannings'>) {
    // Implement planning deletion logic here
    console.log('Deleting planning:', planning);
    // You would add actual deletion logic here
  }

  handleMovePlanningToggle(event: { planning: Tables<'plannings'>, index: number }) {
    this.showMovePlanningPopup = !this.showMovePlanningPopup;
    this.planningIdx = event.index;
  }

  async handlePlanningMove(event: { planning: Tables<'plannings'>, folderId: string }) {
    // Implement logic to move planning to another folder
    console.log('Moving planning:', event.planning, 'to folder:', event.folderId);
    // You would add actual move logic here
  }
}
