import {Injectable} from '@angular/core';
import {LoadingController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading: any;

  constructor(
    private loadingCtrl: LoadingController
  ) {
  }

  async showWithMessage(message: string) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      spinner: "dots"
    });

    this.loading.present();
  }

  async show() {
    this.loading = await this.loadingCtrl.create({
      spinner: "dots"
    });

    this.loading.present();
  }

  async hide() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }
}
